import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DeliveryMethod from '../presenters/DeliveryMethod';
import { setIsForwarder } from '../../../action/salesOrder';

const propTypes = {};

const defaultProps = {};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class DeliveryMethodContainer extends PureComponent {
  render() {
    return <DeliveryMethod {...this.props} />;
  }
}

export default connect((state) => ({
  company: state.company,
}))(DeliveryMethodContainer);

DeliveryMethodContainer.PropTypes = propTypes;
DeliveryMethodContainer.defaultProps = defaultProps;
