import withGetArrayData from '../../../HOCs/withGetArrayData';
import DataDropdown from './DataDropdown';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const DeliveryMethod = withGetArrayData(
  DataDropdown,
  '/SalesOrderDeliveryMethod',
  function (data) {
    this.props.company === 'Aurender Inc.'
      ? (data = data.aurender)
      : (data = data.aurenderAmerica);
    return data.map((item) => {
      return {
        key: item.id,
        text: item.name,
        value: item.id,
      };
    });
  },
  {
    company: localStorage.getItem('NowCompany'),
  }
);

export default withReduxFormValidation(DeliveryMethod);
