import React from 'react';
import AurenderLogo from '../../../images/aurender_logo_color.png';
import '../SalesOrder.css';
import CustomerShippingAdress from './CustomerShippingAdress';
import CustomerBillingAdress from './CustomerBillingAdress';
import SalesOrderPDFProductListItem from './SalesOrderPDFProductListItem';
import NumberFormat from 'react-number-format';
import CustomerContact from './CustomerContact';
import CustomerAddress from './CustomerAddress';

export default (props) => {
  const itemList = props.items.map((item, index) => (
    <SalesOrderPDFProductListItem
      {...item}
      index={index}
      isShowDiscount={props.isShowDiscount}
    />
  ));
  const Rate = props.items.reduce(function (a, b) {
    return (
      a +
      (b.Currency == null
        ? b.USDRetail
        : b.Currency.trim() == 'USD'
          ? b.USDRetail
          : b.KRWRetail) *
        b.Quantity
    );
  }, 0);
  const Amount = props.items.reduce(function (a, b) {
    return a + b.Amount;
  }, 0);
  console.log(Amount);
  const Currency =
    props.items[0] == null
      ? 'USD'
      : props.items[0].Currency == null
        ? 'USD'
        : props.items[0].Currency;

  return (
    <div
      className="SalesOrderPDF__Container"
      id="TempInvoicePDFView"
      {...props}
    >
      <header className="SalesOrderPDF__Header">
        <div className="SalesOrderPDF__Header--Company">
          <img
            src={AurenderLogo}
            className="SalesOrderPDF__Header--CompanyLogo"
          />
          <br />
          <strong className="SalesOrderPDF__Header--CompanyName">
            Aurender Inc.
          </strong>
          <p className="SalesOrderPDF__Header--CompanyAddress">{`1612, Obiz Tower, Dongan-gu, Anyang-si  14057 
          South Korea`}</p>
        </div>
        <div className="SalesOrderPDF__Header--SalesOrderInfo">
          <strong className="SalesOrderPDF__Header--SalesOrder">Invoice</strong>
        </div>
      </header>
      <article className="SalesOrderPDF__Article">
        <section className="SalesOrderPDF__Article--section">
          <CustomerAddress
            header="Billing Address"
            accountName={
              props.detail.BillingAccountName ||
              props.detail.BillingCustomerName
            }
            address={props.detail.BillingAddress}
            state={props.detail.BillingState}
            city={props.detail.BillingCity}
            postal_code={props.detail.BillingPostalCode}
            countryName={props.detail.BillingCountry}
            isEditable={false}
          />
          <CustomerContact
            header="Billing Contact"
            name={props.detail.BillingContactName}
            email={props.detail.BillingContactEmail}
            phone={props.detail.BillingContactPhone}
            mobile_phone={props.detail.BillingContactMobilePhone}
          />
        </section>
        <section className="SalesOrderPDF__Article--ShipToContainer SalesOrderPDF__Article--section">
          <CustomerAddress
            header="Shipping Address"
            accountName={
              props.detail.ShippingAccountName ||
              props.detail.ShippingCustomerName
            }
            address={props.detail.ShippingAddress}
            state={props.detail.ShippingState}
            city={props.detail.ShippingCity}
            postal_code={props.detail.ShippingPostalCode}
            countryName={props.detail.ShippingCountry}
            isEditable={false}
          />
          <CustomerContact
            header="Shipping Contact"
            name={props.detail.ShippingContactName}
            email={props.detail.ShippingContactEmail}
            phone={props.detail.ShippingContactPhone}
            mobile_phone={props.detail.ShippingContactMobilePhone}
          />
        </section>
        <section className="SalesOrderPDF__Article--SalesOrderTypes">
          <table className="SalesOrderPDF__Article--SalesOrderTypesTable">
            <tbody>
              <tr>
                <td className="SalesOrderPDF__Article--SalesOrderTypesName">
                  Delivery Method :
                </td>
                <td className="SalesOrderPDF__Article--SalesOrderTypesValue">
                  {props.detail.DeliveryMethod}
                </td>
              </tr>
              <tr>
                <td className="SalesOrderPDF__Article--SalesOrderTypesName">
                  Terms of Delivery :
                </td>
                <td className="SalesOrderPDF__Article--SalesOrderTypesValue">
                  {props.detail.TermsOfDelivery}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="SalesOrderPDF__Article--Product">
          <table className="SalesOrderPDF__Article--ProductTable">
            <thead>
              <tr>
                <th>#</th>
                <th>Item & Description</th>
                <th>Qty</th>
                <th>List Price</th>
                {props.isShowDiscount ? <th>Discount</th> : null}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
          <div className="SalesOrderPDF__Article--Total">
            <table className="SalesOrderPDF__Article--TotalTable">
              <tbody>
                {/* <tr>
                  <td>List Price Total</td>
                  <td><NumberFormat value={Rate} displayType={'text'} thousandSeparator={true}
                    prefix={Currency.trim() == "KRW" ? '₩' : '$'} /></td>

                  <td>Amount Total</td>
                  <td><NumberFormat value={Amount} displayType={'text'} thousandSeparator={true}
                    prefix={Currency.trim() == "KRW" ? '₩' : '$'} /></td>
                </tr> */}
                {props.isShowDiscount ? (
                  <tr>
                    <td>List Price Total</td>
                    <td>
                      <NumberFormat
                        value={Rate}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>

                    <td>Amount Total</td>
                    <td>
                      <NumberFormat
                        value={Amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                ) : null}
                {Math.floor(props.detail.ShippingCharge) == 0 ? null : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Shipping Charge</td>
                    <td>
                      <NumberFormat
                        value={props.detail.ShippingCharge}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                )}
                {Math.floor(props.detail.Adjustment) == 0 ? null : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Adjustment</td>
                    <td>
                      <NumberFormat
                        value={props.detail.Adjustment}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                )}
                <tr className="SalesOrderPDF__Article--TotalTableTotal">
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>
                    <NumberFormat
                      value={props.detail.Amount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {props.detail.note ? (
            <section>
              <h4>Note</h4>
              <div className="SalesOrderPDF__BackAccountContainer">
                {props.detail.note.split('\n').map((text) => (
                  <p>{text}</p>
                ))}
              </div>
            </section>
          ) : null}
          {props.detail.packing_info ? (
            <section>
              <h4>Packing Info</h4>
              <div className="SalesOrderPDF__BackAccountContainer">
                {props.detail.packing_info.split('\n').map((text) => (
                  <p>{text}</p>
                ))}
              </div>
            </section>
          ) : null}
        </section>
        {props.isFTA ? (
          <section>
            <h4>COUNTRY OF ORIGIN : REPUBLIC OF KOREA.</h4>
            <p>
              The exporter of the products covered by this document (customs
              authorisation No 010-17-200049) declares that, except where
              otherwise clearly indicated, these products are of KR preferential
              origin.
            </p>
          </section>
        ) : null}
        <section>
          <h4>Payment Instructions</h4>
          <div className="SalesOrderPDF__BackAccountContainer">
            <p>All orders prepaid to: </p>
            <p>Beneficiary: Aurender Inc.</p>
            <p>Account: 1081-400-693115 Bank: Woori Bank </p>
            <p>Branch: Gasan High Tech Branch </p>
            <p>
              Address: 103 Ace High-End Tower, Gasan-dong 345-4, Geumcheon-gu,
              Seoul, South Korea{' '}
            </p>
            <p>SWIFT: HVBKKRSEXXX</p>
          </div>
        </section>
      </article>
    </div>
  );
};
