import React, { Component } from 'react';
import './MenuBar.css';

class MenuHighLighter extends Component {
  render() {
    const { top } = this.props;
    return (
      <div
        className={'MenuBar__Menu--highlighter'}
        style={{ top: `${top}px` }}
      ></div>
    );
  }
}

export default MenuHighLighter;
