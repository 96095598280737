import React, { Component, PropTypes } from 'react';
import {
  Field,
  Fields,
  reduxForm,
  getFormValues,
  getFormSubmitErrors,
  isDirty,
} from 'redux-form';

import {
  MoneyInput,
  LabelInput,
  ColumnLabel,
  ProductSuggestbox,
  LabelwithDropbox,
} from '../SalesOrder/presenters/customComponents';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Menu,
  Segment,
  Input,
  Dropdown,
  Label,
  List,
  Table,
} from 'semantic-ui-react';

import SalesOrderType from '../SalesOrder/presenters/SalesOrderType';
import TermsofDelivery from '../SalesOrder/presenters/TermsofDelivery';

import Product from '../SalesOrder/containers/Product';
import CustomerContainer from '../SalesOrder/containers/CustomerContainer';
import SalesOrderDate from '../SalesOrder/presenters/SalesOrderDate';
import TotalContainer from '../SalesOrder/containers/TotalContainer';
import ButtonContainer from './containers/ButtonContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setSalesOrderInitailReduxFormValue,
  getSalesOrderFormData,
  getInvoiceFormData,
} from '../../action/salesOrder';
import {
  setInitialProductOrderList,
  getSalesOrderItems,
  getInvoiceItems,
  getInvoiceEditItems,
  getInvoiceAddableItems,
} from '../../action/product';
import { FingurLoader } from '../General/FingurLoader';
import { initCustomerAdress, initSelectedAddress } from '../../action/customer';
import SalesOrderTestCheckBox from '../SalesOrder/presenters/SalesOrderTestCheckBox';
import DeliveryMethodContainer from '../SalesOrder/containers/DeliveryMethodContainer';
import ForwarderDropdown from '../SalesOrder/presenters/ForwarderDropdown';

import Axios from '../../util/CustomAxios';

import formatDate from '../../util/dateToYYYY-MM-DD';

import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'; // ES6
import '../../styles/FadeInOut.css';
import '../../styles/SalesOrderBottom.css';

const validate = (values) => {
  const errors = {};

  if (!values.SOIssueDate) {
    errors.SOIssueDate = 'Required';
  }
  if (!values.SOType) {
    errors.SOType = 'Required';
  }
  if (!values.billingAccountID) {
    errors.AccountID = 'Required';
  }
  if (!values.shippingAccountID) {
    errors.AccountID = 'Required';
  }
  // if (!values.BillTo) {
  //   errors.BillTo = 'Required'
  // }
  // if (!values.ShipTo) {
  //   errors.ShipTo = 'Required'
  // }
  if (!values.bill_to) {
    errors.bill_to = 'Required';
  }
  if (!values.ship_to) {
    errors.ship_to = 'Required';
  }
  if (!values.TermsOfDelivery) {
    errors.TermsOfDelivery = 'Required';
  }
  if (!values.DeliveryMethod) {
    errors.DeliveryMethod = 'Required';
  }

  return errors;
};

const CustomerC = (fields) => <CustomerContainer fields={fields} />;
const salesOrderFormInitailValue = {
  Adjustment: 0,
  ShippingCharge: 0,
  SOIssueDate: formatDate(new Date()),
  Test: window.location.host.indexOf('localhost') > -1 ? true : false,
};

const forwarderEnableStyle = {
  transform: 'translateY(10px)',
  transition: 'transform 0.5s cubic-bezier(0.18, 0.55, 0.2, 1)',
};

class NewInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevDeliveryMethod: null,
    };
  }

  async componentDidMount() {
    this.props.setInitialProductOrderList();
    if (this.props.isEdit) {
      const SOID = this.props.match.params.SOID;
      this.props.getInvoiceFormData(SOID);
      this.props.getInvoiceEditItems(SOID);
      let result = await Axios.get(`/Invoice/isPartial/${SOID}`);
      this.setState({
        isPartial: result.data.isPartial,
      });
    } else {
      const SOID = this.props.match.params.SOID;
      this.props.getSalesOrderFormData(SOID);
      this.props.getInvoiceItems(SOID);
      //   this.props.setSalesOrderInitailReduxFormValue(salesOrderFormInitailValue)
      //   this.props.initCustomerAdress();
      //   this.props.initSelectedAddress();
    }
  }

  onAddOtherProductsButtonClick = (e) => {
    const SOID = this.props.match.params.SOID;
    this.props.getInvoiceAddableItems(SOID);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      prevDeliveryMethod: this.props.deliveryMethod,
    });
  }

  render() {
    const {
      handleSubmit,
      setInitailFormValue,
      initialValues,
      isSalesOrderFormDataLoading,
      deliveryMethod,
      test,
    } = this.props;

    let isForwarder = deliveryMethod === 3 ? true : false;
    let bottomRowStyle = isForwarder
      ? forwarderEnableStyle
      : this.state.prevDeliveryMethod === 3 && deliveryMethod
        ? {
            animationDuration: '0.5s',
            animationName: 'booster',
            animationIterationCount: '1',
            animationDirection: 'alternate',
          }
        : {};

    return (
      <React.Fragment>
        {isSalesOrderFormDataLoading ? (
          <FingurLoader />
        ) : (
          <form
            onSubmit={handleSubmit}
            onKeyUp={(e) => {
              const keyCode = e.keyCode || e.which;
              if (keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
            onKeyPress={(e) => {
              const keyCode = e.keyCode || e.which;
              if (keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
          >
            {
              <div>
                <Container>
                  <Header as="h1" dividing>
                    <span>
                      {this.props.isEdit ? 'Edit Invoice' : 'New Invoice'}
                    </span>
                    <Field name="Test" component={SalesOrderTestCheckBox} />
                  </Header>
                  <br />
                  <Grid columns="equal" stackable>
                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="SalesOrderType" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="SOType"
                          component={SalesOrderType}
                          labelName="Sales Order Type"
                        />
                      </Grid.Column>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Sales Order Date" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field name="SOIssueDate" component={SalesOrderDate} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="top">
                        <ColumnLabel
                          text="Customer"
                          style={{
                            padding: '8px 0',
                            lineHeight: '1em',
                          }}
                        />
                      </Grid.Column>
                      {/* <CustomerContainer /> */}
                      <Fields
                        names={[
                          'billingAccountID',
                          'shippingAccountID',
                          'bill_to',
                          'ship_to',
                        ]}
                        component={CustomerC}
                      />
                    </Grid.Row>
                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Terms Of Delivery" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="TermsOfDelivery"
                          labelName="Terms of Delivery"
                          component={TermsofDelivery}
                        />
                      </Grid.Column>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Delivery Method" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="DeliveryMethod"
                          labelName="Delivery Method"
                          component={DeliveryMethodContainer}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <ReactCSSTransitionGroup
                      transitionEnter={true}
                      transitionLeave={true}
                      transitionAppear={true}
                      transitionLeaveTimeout={500}
                      style={{
                        padding: 0,
                        width: '100%',
                        margin: 0,
                      }}
                      className="ui grid"
                      transitionName="Opacity"
                    >
                      {isForwarder ? (
                        <Grid.Row className="stretched row" stretched>
                          <Grid.Column
                            class="middle aligned three wide column"
                            width={3}
                            verticalAlign="middle"
                          ></Grid.Column>
                          <Grid.Column
                            class="middle aligned five wide column"
                            width={5}
                            verticalAlign="middle"
                          ></Grid.Column>
                          <Grid.Column
                            class="middle aligned three wide column"
                            width={3}
                            verticalAlign="middle"
                          >
                            <ColumnLabel text="Forwarder" />
                          </Grid.Column>
                          <Grid.Column
                            class="middle aligned five wide column"
                            width={5}
                            verticalAlign="middle"
                          >
                            <Field
                              name="Forwarder"
                              labelName="Forwarder"
                              component={ForwarderDropdown}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}
                    </ReactCSSTransitionGroup>

                    <div style={bottomRowStyle} className="ui grid">
                      <Product isInvoice={true} />
                      {this.state.isPartial ? (
                        <Grid.Row stretched>
                          <Grid.Column
                            width={3}
                            verticalAlign="middle"
                          ></Grid.Column>
                          <Grid.Column width={13} verticalAlign="middle">
                            <a onClick={this.onAddOtherProductsButtonClick}>
                              <h2>+ Add other Products</h2>
                            </a>
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}
                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Adjustment" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <Field
                            name="Adjustment"
                            component={MoneyInput}
                            prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                            selectedCustomer={this.props.selectedCustomer}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Shipping Charge" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <Field
                            name="ShippingCharge"
                            component={MoneyInput}
                            prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                            selectedCustomer={this.props.selectedCustomer}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Total" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <TotalContainer />
                        </Grid.Column>
                      </Grid.Row>
                      <ButtonContainer
                        isEdit={this.props.isEdit}
                        SOID={this.props.match.params.SOID}
                      />
                    </div>
                  </Grid>
                </Container>
              </div>
            }
          </form>
        )}
      </React.Fragment>
    );
  }
}

export default NewInvoice = connect(
  (state) => ({
    initialValues: state.salesOrderFormValue,
    enableReinitialize: true,
    selectedCustomer: state.selectedCustomer.billingCustomer,
    currency:
      state.selectedCustomer.billingCustomer.Currency != undefined
        ? state.selectedCustomer.billingCustomer.Currency.trim()
        : 'USD',
    deliveryMethod: !!getFormValues('SalesOrder')(state)
      ? getFormValues('SalesOrder')(state).DeliveryMethod
      : null,
    test: getFormValues('SalesOrder')(state),
    shouldValidate: () => isDirty('SalesOrder')(state),
  }),
  (dispatch) => ({
    setSalesOrderInitailReduxFormValue: bindActionCreators(
      setSalesOrderInitailReduxFormValue,
      dispatch
    ),
    getSalesOrderFormData: bindActionCreators(getSalesOrderFormData, dispatch),
    getInvoiceFormData: bindActionCreators(getInvoiceFormData, dispatch),
    getInvoiceItems: bindActionCreators(getInvoiceItems, dispatch),
    getInvoiceEditItems: bindActionCreators(getInvoiceEditItems, dispatch),
    getInvoiceAddableItems: bindActionCreators(
      getInvoiceAddableItems,
      dispatch
    ),
    initCustomerAdress: bindActionCreators(initCustomerAdress, dispatch),
    setInitialProductOrderList: bindActionCreators(
      setInitialProductOrderList,
      dispatch
    ),
    initSelectedAddress: bindActionCreators(initSelectedAddress, dispatch),
  })
)(
  reduxForm({
    validate,
    form: 'SalesOrder',
  })(NewInvoice)
);
