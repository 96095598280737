import React from 'react';
import axios from 'axios';
import { Grid, Table } from 'semantic-ui-react';
import '../SalesOrder.css';

import SalesOrderProductListItem from './SalesOrderProductListItem';

const SalesOrderProductList = ({ items }) => {
  const ListItems = items.map((item, index) => (
    <SalesOrderProductListItem {...item} key={index} />
  ));
  return (
    <Table singleLine basic="very" collapsing>
      <Table.Header style={{ background: 'lightgrey' }}>
        <Table.Row>
          <Table.HeaderCell width={4} id="ListHeaders" className="ListHeader">
            Items
          </Table.HeaderCell>
          <Table.HeaderCell
            width={1}
            id="ListHeaders"
            className="ListHeader"
            style={{ textAlign: 'right', marginRight: '14px' }}
          >
            Ordered
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            id="ListHeaders"
            className="ListHeader"
            style={{ textAlign: 'right', marginRight: '14px' }}
          >
            Rate
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            id="ListHeaders"
            className="ListHeader"
            style={{ textAlign: 'right', marginRight: '14px' }}
          >
            Discount
          </Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            id="ListHeaders"
            className="ListHeader"
            style={{ textAlign: 'right', paddingRight: '14px' }}
          >
            FOB Price
          </Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            id="ListHeaders"
            className="ListHeader"
            style={{ textAlign: 'right', paddingRight: '14px' }}
          >
            Amount
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {ListItems}
        {/* <Table.Row>
                    <Table.Cell id="ListItems" className="ListItem">John Lilki</Table.Cell>
                    <Table.Cell>September 14, 2013</Table.Cell>
                    <Table.Cell>jhlilk22@yahoo.com</Table.Cell>
                    <Table.Cell>No</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell id="ListItems" className="ListItem">Jamie Harington</Table.Cell>
                    <Table.Cell>January 11, 2014</Table.Cell>
                    <Table.Cell>jamieharingonton@yahoo.com</Table.Cell>
                    <Table.Cell>Yes</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell id="ListItems" className="ListItem">Jill Lewis</Table.Cell>
                    <Table.Cell>May 11, 2014</Table.Cell>
                    <Table.Cell>jilsewris22@yahoo.com</Table.Cell>
                    <Table.Cell>Yes</Table.Cell>
                </Table.Row> */}
      </Table.Body>
    </Table>
  );
};

export default SalesOrderProductList;
