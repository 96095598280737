import React, { Component, PropTypes } from 'react';
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Input,
  Icon,
} from 'semantic-ui-react';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSaveButtonClickEvnetHandler =
      this.onSaveButtonClickEvnetHandler.bind(this);
  }
  handleOpen() {
    this.setState({
      modalOpen: true,
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }
  onSaveButtonClickEvnetHandler(event, data) {
    this.props.onConfirmButtonClickEvent();
    this.handleClose();
  }

  render() {
    return (
      <Modal
        size="tiny"
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button floated="right" onClick={this.handleOpen}>
            {this.props.Content}
          </Button>
        }
      >
        <Modal.Header>
          Are you sure you want to {this.props.Content.toLowerCase()} '
          {this.props.Target}'
        </Modal.Header>
        <Modal.Actions>
          <Button color="red" onClick={this.handleClose}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={this.onSaveButtonClickEvnetHandler}>
            <Icon name="checkmark" /> Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ConfirmModal;
