import React, { Component, PropTypes } from 'react';
import PropTypes_ from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import backgroundImage from '../images/Seattle.jpg';
import '../styles/App.css';

import Main from './Main/Main';
import InAndOut from './InAndOut/InAndOut';
import NewSalesOrder from './SalesOrder/NewSalesOrder';
import SalesOrderList from './SalesOrder/SalesOrderList';
import SalesOrderItem from './SalesOrder/SalesOrderItem';

import { Link } from 'react-router-dom';
import MenuBar from './MenuBar/MenuBar';
import HomeImg from '../images/Home.png';
import AccountImg from '../images/Account.png';
import SalesOrderImg from '../images/SalesOrder.png';
import ProductImg from '../images/Product.png';
import InvoiceImg from '../images/Invoice.png';
import ManagementImg from '../images/Management.png';
import InventoryImg from '../images/Inventory.png';
import TestImg from '../images/flask.svg';
import { LoadingWrapper } from './General/LoadingWrapper';
import NewSalesOrderContainer from './SalesOrder/containers/NewSalesOrderContainer';
import AccountList from './Account/AccountList';
import NewAccount from './Account/NewAccount';
import AccountDetail from './Account/AccountDetail';
import Management from './Management/Management';
import ExperimentalPage from './Experimental/ExperimentalPage';
import InvoiceList from './Invoice/InvoiceList';
import InvoiceItem from './Invoice/InvoiceItem';

import { LicenseManager } from 'ag-grid-enterprise/main';
import NewInvoice from './Invoice/NewInvoice';
LicenseManager.setLicenseKey(
  'Evaluation_License_Valid_Until__13_October_2018__MTUzOTM4NTIwMDAwMA==b5fbbcd5248da7b75520ceb913b70cfb'
);

const propTypes = {};

const defaultProps = {};

const menus = [
  {
    icon: <img src={HomeImg} />,
    menu: 'Home',
    path: '/',
  },
  {
    icon: <img src={AccountImg} />,
    menu: 'Account',
    path: '/Account',
  },
  {
    icon: <img src={SalesOrderImg} />,
    menu: 'SalesOrder',
    path: '/SalesOrder',
  },
  {
    icon: <img src={ProductImg} />,
    menu: 'Product',
    path: '/Product',
  },
  {
    icon: <img src={InvoiceImg} />,
    menu: 'Invoice',
    path: '/Invoice',
  },
  {
    icon: <img src={ManagementImg} />,
    menu: 'Management',
    path: '/Management',
  },
  {
    icon: <img src={InventoryImg} />,
    menu: 'Inventory',
    path: '/Inventory',
  },
  {
    icon: <img src={TestImg} />,
    menu: 'Experimental',
    path: '/Experimental',
  },
];

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const submit = (values) => {
      // print the form values to the console
    };
    return (
      <Router>
        <div>
          <MenuBar menus={menus} backgroundImg={backgroundImage} />
          <Switch>
            <div className="Page-container">
              <Route exact path="/" component={Main} />
              <Route path="/InAndOut" component={InAndOut} />
              <Route exact path="/SalesOrder" component={SalesOrderList} />
              <Route
                exact
                path="/SalesOrder/:SOID"
                component={SalesOrderItem}
              />
              <Route
                path="/SalesOrder/edit/:SOID"
                render={(props) => (
                  <NewSalesOrderContainer {...props} isEdit={true} />
                )}
              />
              <Route
                path="/NewSalesOrder"
                render={(props) => (
                  <NewSalesOrder {...props} onSubmit={submit} />
                )}
              />

              <Route
                path="/SalesOrder/new-invoice/:SOID"
                render={(props) => <NewInvoice {...props} onSubmit={submit} />}
              />
              <Route
                exact
                path="/Invoice/edit/:SOID"
                render={(props) => (
                  <NewInvoice {...props} onSubmit={submit} isEdit={true} />
                )}
              />
              <Route exact path="/Invoice" component={InvoiceList} />
              <Route exact path="/Invoice/:SOID" component={InvoiceItem} />
              <Route exact path="/Account" component={AccountList} />
              <Route path="/NewAccount" component={NewAccount} />
              <Route path="/AccountDetail" component={AccountDetail} />
              <Route path="/TESt" component={Management} />
              <Route path="/Experimental" component={ExperimentalPage} />
            </div>
          </Switch>
        </div>
      </Router>
    );
  }
}

App.PropTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
