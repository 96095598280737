import withGetArrayData from '../../../HOCs/withGetArrayData';
import DataDropdown from './DataDropdown';

const StyledSalesOrderPaidStatusDropdown = withGetArrayData(
  DataDropdown,
  '/SalesOrderPaidStatus',
  (array) =>
    array.map((item) => {
      return {
        key: item.id,
        text: item.name,
        value: item.id,
      };
    })
);

export default StyledSalesOrderPaidStatusDropdown;
