import Modal from 'react-responsive-modal';
import SalesOrderItem from '../SalesOrderItem';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { matchSorter } from 'match-sorter';
import ReactTable from 'react-table';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import '../SalesOrder.css';
import UserDropdown from './UserDropdown';
import SalesOrderTypeDropdown from './SalesOrderTypeDropdown';
import SalesOrderPaidStatusDropdown from './SalesOrderPaidStatusDropdown';

export default class SalesOrderListTable extends Component {
  componentWillMount() {
    this.setState({
      showModal: false,
    });
  }

  componentWillReceiveProps(nextProps) {}

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      soid: 0,
      filterDate: '',
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDatePicked = this.handleDatePicked.bind(this);
    this.handleShipmentDatePicked = this.handleShipmentDatePicked.bind(this);
    this.handlePickUpDataPicked = this.handlePickUpDataPicked.bind(this);
  }

  handleOpenModal(soid) {
    this.setState({ showModal: true, soid: soid });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleDatePicked(date) {
    this.setState({
      startDate: date,
    });
    this.props.setDateFilterEnable(
      date != null ? date.format('YYYY-MM-DD') : ''
    );
    console.log('datepicked data : ', this.props.date);
  }

  handleShipmentDatePicked(date) {
    this.setState({
      startSmDate: date,
    });
    this.props.setShipmentDateFilterEnable(
      date != null ? date.format('YYYY-MM-DD') : ''
    );
    console.log('datepicked data : ', this.props.date);
  }

  handlePickUpDataPicked(date) {
    this.setState({
      startPuDate: date,
    });
    this.props.setShipmentDateFilterEnable(
      date != null ? date.format('YYYY-MM-DD') : ''
    );
    console.log('datepicked data : ', this.props.date);
  }

  render() {
    const { showModal, soid, startDate, startSmDate, filterDate, startPuDate } =
      this.state;
    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          console.log('It was in this row:', rowInfo);
          this.handleOpenModal(rowInfo.original.SOID);
          console.log(this.state.isCurrent);
          console.log('soissuedate : ', rowInfo.original.SOIssueDate);
        },
      };
    };

    return (
      <React.Fragment>
        <ReactTable
          data={this.props.data}
          filterable
          defaultPageSize={20}
          key={this.props.data.SOID}
          style={{ cursor: 'pointer' }}
          className="-striped -highlight"
          getTrProps={onRowClick}
          defaultSorted={[
            {
              id: 'SOIssueDate',
              desc: true,
            },
          ]}
          defaultFiltered={[
            {
              id: 'Status',
              value: 'All',
            },
          ]}
          columns={[
            {
              Header: 'SO Number',
              id: 'soNum',
              accessor: 'SONumber',
              filterable: true,
              filterMethod: (filter, row) =>
                matchSorter(row, filter.value, {
                  keys: ['soNum'],
                }),
              filterAll: true,
              sortMethod: (a, b) => {
                if (a.length === b.length) {
                  return a > b ? 1 : -1;
                }
                return a.length > b.length ? 1 : -1;
              },
            },
            {
              Header: 'Date',
              accessor: 'SOIssueDate',
              id: 'SOIssueDate',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <DatePicker
                  id="datePicker"
                  isClearable={true}
                  style={{ width: '100%' }}
                  popperClassName="react-datepicker-popper"
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Select a date"
                  selected={this.state.startDate}
                  onChange={this.handleDatePicked}
                />
              ),
            },
            {
              Header: 'Date Of PickUp',
              accessor: 'DateofPickUp',
              id: 'DateofPickUp',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <DatePicker
                  id="datePicker"
                  isClearable={true}
                  style={{ width: '100%' }}
                  popperClassName="react-datepicker-popper"
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Select a date"
                  selected={this.state.startPuDate}
                  onChange={this.handlePickUpDataPicked}
                />
              ),
            },
            {
              Header: 'Date Of Shipment',
              accessor: 'DateofShipment',
              id: 'DateofShipment',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <DatePicker
                  id="datePicker"
                  isClearable={true}
                  style={{ width: '100%' }}
                  popperClassName="react-datepicker-popper"
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Select a date"
                  selected={this.state.startSmDate}
                  onChange={this.handleShipmentDatePicked}
                />
              ),
            },
            {
              Header: 'Customer',
              accessor: 'Customer',
              filterable: true,
              filterMethod: (filter, row) =>
                row == null
                  ? false
                  : matchSorter(row, filter.value, {
                      keys: ['Customer'],
                    }),
              filterAll: true,
              width: 200,
            },
            {
              Header: 'Shipping Account',
              accessor: 'shipping_account_name',
              filterable: true,
              filterMethod: (filter, row) =>
                row == null
                  ? false
                  : matchSorter(row, filter.value, {
                      keys: ['shipping_account_name'],
                    }),
              filterAll: true,
              width: 200,
            },
            {
              Header: 'Status',
              accessor: 'Status',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <select
                  style={{ width: '100%' }}
                  onChange={(event) => onChange(event.target.value)}
                >
                  <option value="All">All Status</option>
                  <option value="Draft">Draft</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Packed">Packed</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Invoiced">Invoiced</option>
                  <option value="SO Issued">SO Issued</option>
                  <option value="Partial Shipping">Partial Shipping</option>
                  <option value="Void">Void</option>
                </select>
              ),
              filterMethod: (filter, row) => {
                if (filter.value == 'All') {
                  return !row._original.isDeleted;
                } else if (filter.value != 'Void') {
                  return row.Status.includes(filter.value);
                } else {
                  return row._original.isDeleted;
                }
              },
            },
            {
              id: 'amount',
              Header: 'Amount',
              accessor: 'Amount',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: '100%' }}
                  value={filter ? filter.value : 'all'}
                >
                  <option value="all">All Curency</option>
                  <option value="KRW">KRW</option>
                  <option value="USD">USD</option>
                  <option value="CNY">CNY</option>
                </select>
              ),
              filterMethod: (filter, row) => {
                if (filter.value == 'all') return true;
                else {
                  return row._original.Currency.includes(filter.value);
                }
              },
              sortMethod: (a, b) => {
                if (
                  a.props.children.props.value.length ===
                  b.props.children.props.value.length
                ) {
                  return a.props.children.props.value >
                    b.props.children.props.value
                    ? 1
                    : -1;
                }
                return a.props.children.props.value.length >
                  b.props.children.props.value.length
                  ? 1
                  : -1;
              },
            },
            {
              id: 'sales_order_type',
              Header: 'SO Type',
              accessor: 'sales_order_type',
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['sales_order_type'],
                }),
              Filter: ({ filter, onChange }) => (
                <SalesOrderTypeDropdown
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                />
              ),
              Cell: (row) => <p style={{ textAlign: 'right' }}>{row.value}</p>,
            },
            {
              id: 'paid',
              Header: 'Paid',
              accessor: 'paid',
              filterAll: true,
              filterMethod: (filter, rows) => {
                console.log(filter);
                console.log(rows);
                return rows.filter((row) => row.paid === filter.value);
              },
              // matchSorter(rows, filter.value, { keys: ["paid"] }),
              Filter: ({ filter, onChange }) => (
                <SalesOrderPaidStatusDropdown
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                />
              ),
              Cell: (row) => <p style={{ textAlign: 'right' }}>{row.value}</p>,
            },
            {
              id: 'create_user_name',
              Header: 'Creator',
              accessor: 'create_user_name',
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['create_user_name'],
                }),
              Filter: ({ filter, onChange }) => (
                <UserDropdown
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                />
              ),
              Cell: (row) => <p style={{ textAlign: 'right' }}>{row.value}</p>,
            },
            {
              id: 'edit_user_name',
              Header: 'Last Editor',
              accessor: 'edit_user_name',
              filterable: true,
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['edit_user_name'],
                }),
              Filter: ({ filter, onChange }) => (
                <UserDropdown
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                />
              ),
              Cell: (row) => <p style={{ textAlign: 'right' }}>{row.value}</p>,
            },
          ]}
        />
        <div>
          <Modal
            open={this.state.showModal}
            onClose={this.handleCloseModal}
            closeIcon={10}
          >
            <br />
            <br />
            <SalesOrderItem SOID={this.state.soid} />
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
