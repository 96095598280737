import React, { Component, PropTypes } from 'react';
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Input,
  Icon,
} from 'semantic-ui-react';
import axios from '../../../util/CustomAxios';
import SalesOrderDateManager from './SalesOrderDateManager';
import dateConverter from '../../../util/dateToYYYY-MM-DD';
import SalesOrderPaidStatusManager from './SalesOrderPaidStatusManager';
import { getSalesOrderList } from '../../../action/salesOrder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SalesOrderMutableStatusManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      pickUpDate: dateConverter(new Date()),
      shipmentDate: dateConverter(new Date()),
      dateChanged: false,
      soDates: props.soDates,
      paid_id: props.paid_id,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDateChangeEventHandler = this.onDateChangeEventHandler.bind(this);
    this.onPaidStatusChange = this.onPaidStatusChange.bind(this);
    this.onSaveButtonClickEvnetHandler =
      this.onSaveButtonClickEvnetHandler.bind(this);
    this.editDates = this.editDates.bind(this);
    this.editPaidStatus = this.editPaidStatus.bind(this);
    this.updateSOList = this.updateSOList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      soDates: nextProps.soDates,
      paid_id: nextProps.paid_id,
    });
  }

  onDateChangeEventHandler(dates) {
    this.setState({
      soDates: { ...dates },
      dateChanged: true,
    });
  }

  onPaidStatusChange(value) {
    this.setState({
      paid_id: value,
    });
  }

  handleOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  async editDates() {
    await axios.put(`/SalesOrder/edit/${this.props.SOID}/Dates`, {
      PickUpDate:
        this.state.soDates.DateofPickUp == ''
          ? null
          : new Date(this.state.soDates.DateofPickUp)
              .toISOString()
              .substring(0, 10),
      ShipmentDate:
        this.state.soDates.DateofShipment == ''
          ? null
          : new Date(this.state.soDates.DateofShipment)
              .toISOString()
              .substring(0, 10),
    });
  }

  async editPaidStatus() {
    await axios.put(`/SalesOrder/edit/${this.props.SOID}/Paid`, {
      paid_id: this.state.paid_id,
    });
  }

  async onSaveButtonClickEvnetHandler(event, data) {
    try {
      if (this.state.dateChanged) {
        await this.editDates();
      }
      await this.editPaidStatus();
      this.updateSOList();
      this.props.setIsSODatesUpdateComplete(true);
      this.handleClose();
    } catch (err) {
      console.error(err);
    }
  }

  updateSOList() {
    this.props.getSalesOrderList(this.props.company);
  }

  render() {
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button floated="right" onClick={this.handleOpen}>
            Manage
          </Button>
        }
      >
        <Modal.Header>Manage SO</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid columns="equal" stackable>
              <SalesOrderDateManager
                onDateChange={this.onDateChangeEventHandler}
                soDates={this.state.soDates}
              />
              <SalesOrderPaidStatusManager
                onChange={this.onPaidStatusChange}
                value={this.state.paid_id}
              />
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.handleClose}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={this.onSaveButtonClickEvnetHandler}>
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    company: state.company,
  }),
  (dispatch) => ({
    getSalesOrderList: bindActionCreators(getSalesOrderList, dispatch),
  })
)(SalesOrderMutableStatusManager);
