import React from 'react';
import configureStore from './store/index';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import reducer from './reducer/index';
import App from './component/App';
import './store/index';

const store = configureStore(reducer);

const render = () => {
  if (
    window.location.host.indexOf('localhost') === -1 &&
    window.location.protocol !== 'https:'
  ) {
    // window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
    // return // to stop app from mounting
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

store.subscribe(render);
render();
