import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCompany } from '../../action/user';

import Hamburger from 'hamburger-react';
import Menu from './Menu';
import MenuHighLighter from './MenuHighLighter';
import './MenuBar.css';
import axios from '../../util/CustomAxios';
import DefaultProfileImg from '../../images/default_profile.png';

class MenuBar extends Component {
  constructor() {
    super();
    this.state = {
      forceOpen: false,
      open: false,
      selectedMenuIndex: 0,
      company: undefined,
    };
    this.hamburgerButtonOnClickHandler =
      this.hamburgerButtonOnClickHandler.bind(this);
    this.menuOnClickHandler = this.menuOnClickHandler.bind(this);
    this.CompanyDropDownOnChangeHandler =
      this.CompanyDropDownOnChangeHandler.bind(this);
    this.menuBarOnMouseEnter = this.menuBarOnMouseEnter.bind(this);
    this.menuBarOnMouseLeave = this.menuBarOnMouseLeave.bind(this);
  }

  componentWillMount() {
    let selectedMenuIndex;
    this.props.menus.forEach((menu, index) => {
      if (menu.path === window.location.pathname) {
        selectedMenuIndex = index;
        return;
      }
    });
    if (localStorage.getItem('NowCompany') == 'null') {
      localStorage.setItem('NowCompany', localStorage.getItem('Company'));
      this.props.setCompany(localStorage.getItem('Company'));
      this.setState({
        defaultCompany: localStorage.getItem('Company'),
        selectedMenuIndex: selectedMenuIndex,
      });
    } else {
      this.props.setCompany(localStorage.getItem('NowCompany'));
      this.setState({
        defaultCompany: localStorage.getItem('NowCompany'),
        selectedMenuIndex: selectedMenuIndex,
      });
    }
  }

  hamburgerButtonOnClickHandler() {
    this.setState({
      forceOpen: !this.state.forceOpen,
    });
  }

  menuOnClickHandler(index) {
    this.setState({
      selectedMenuIndex: index,
      open: false,
    });
  }

  CompanyDropDownOnChangeHandler(event, data) {
    localStorage.setItem('NowCompany', data.value);
    this.props.setCompany(data.value);
  }

  menuBarOnMouseEnter() {
    this.setState({
      open: true,
    });
  }

  menuBarOnMouseLeave() {
    this.setState({
      open: false,
    });
  }

  longinTokenCheck() {
    axios
      .get(`/Main/CheckLoginToken`)
      .then((response) => {
        if (response.data != true) {
          localStorage.clear();
        }
      })
      .catch(function (error) {
        localStorage.clear();
        console.log(error);
      });
  }

  render() {
    const { menus, backgroundImg } = this.props;
    const menuConvert = menus.map((menu, index) => {
      const isSelected = index == this.state.selectedMenuIndex ? true : false;
      return (
        <Menu
          key={index}
          icon={menu.icon}
          menu={menu.menu}
          path={menu.path}
          isSelected={isSelected}
          onClick={() => this.menuOnClickHandler(index)}
        />
      );
    });
    const background =
      backgroundImg != undefined ? (
        <img className="Menu__background--image" src={backgroundImg} />
      ) : null;
    const openCssState = this.state.forceOpen
      ? 'open'
      : this.state.open
        ? 'open'
        : 'fold';

    const userinfo = JSON.parse(localStorage.getItem('UserInfo'));
    let blobUrl = null;
    if (userinfo !== null && userinfo.userImage) {
      const buffer = new Uint8Array(userinfo.userImage.data);
      const blob = new Blob([buffer], { type: 'image/jpeg' });
      blobUrl = window.URL.createObjectURL(blob);
    } else {
      blobUrl = DefaultProfileImg;
    }

    const companyOptions = [
      { text: 'Aurender Inc.', value: 'Aurender Inc.' },
      { text: 'Aurender America Inc.', value: 'Aurender America Inc.' },
    ];

    return (
      <div
        className={`MenuBar ${openCssState}`}
        onMouseEnter={this.menuBarOnMouseEnter}
        onMouseLeave={this.menuBarOnMouseLeave}
      >
        <div className="MenuBar__background">{background}</div>
        <MenuHighLighter top={this.state.selectedMenuIndex * 48 + 48} />
        <div
          className="MenuBar__Header"
          onClick={this.hamburgerButtonOnClickHandler}
        >
          <Hamburger toggled={this.state.forceOpen} size={16} color="white" />
        </div>
        {menuConvert}
        {userinfo == undefined ? null : (
          <div>
            <div
              style={{
                position: 'absolute',
                bottom: '32px',
                width: '320px',
                height: '48px',
                maxHeight: '48px',
              }}
            >
              <div className="MenuBar__Menu--name" id="Company_">
                <span>
                  {this.props.company === 'Aurender Inc.' ? 'AK' : 'AA'}
                </span>
              </div>
              <div className="MenuBar__Menu--name" id="Company">
                <Dropdown
                  className="CompanyDropDown"
                  id="Company"
                  inline
                  options={companyOptions}
                  defaultValue={this.state.defaultCompany}
                  upward
                  onChange={this.CompanyDropDownOnChangeHandler}
                />
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: '320px',
                height: '48px',
                maxHeight: '48px',
              }}
            >
              <div className="MenuBar__Menu--icon">
                <img src={blobUrl} />
              </div>
              <div className="MenuBar__Menu--name" id="User">
                <span style={{ fontSize: '18px' }}>
                  {userinfo.firstName + ' ' + userinfo.lastName}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({ company: state.company }),
  (dispatch) => ({
    setCompany: bindActionCreators(setCompany, dispatch),
  })
)(MenuBar);
