import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

export default ({
  SOIID,
  Currency = 'USD',
  Quantity,
  Discount,
  Amount,
  ProductCode,
  Model,
  Color,
  Voltage,
  HDD,
  SSD,
  Item,
  USDRetail,
  KRWRetail,
  Description,
  HS_CODE,
  index,
  serials,
  customDescription,
  isShowDiscount,
  sku,
}) => {
  Currency = Currency == null ? 'USD' : Currency;
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <p className="SalesOrderPDF__Article--ProductName">
          {ProductCode.trim()}
        </p>
        {Model.trim() == 'CS_Parts' ? (
          <p className="SalesOrderPDF__Article--ProductDescription">
            {sku ? sku : `${Model.trim()}-${Item != null ? Item.trim() : ''}`}-
            {Description == undefined ? '' : Description.trim()}
            {Description == undefined || HS_CODE == undefined ? '' : ' / '}
            {HS_CODE == undefined ? '' : HS_CODE.trim()}
          </p>
        ) : (
          <p className="SalesOrderPDF__Article--ProductDescription">
            {sku
              ? sku
              : `${Model.trim()}-${Color.trim()}-${Voltage.trim()}-${HDD.trim()}-${SSD.trim()}`}
            -{Description == undefined ? '' : Description.trim()}
            {Description == undefined || HS_CODE == undefined ? '' : ' / '}
            {HS_CODE == undefined ? '' : HS_CODE.trim()}
          </p>
        )}
        <p className="SalesOrderPDF__Article--ProductDescription">
          {serials == undefined
            ? ''
            : serials.map((serial) => serial.Serial).join(', ')}
        </p>
        <p className="SalesOrderPDF__Article--ProductDescription">
          {customDescription == undefined ? '' : customDescription}
        </p>
      </td>
      <td>{Quantity}</td>
      {isShowDiscount ? (
        <Fragment>
          <td>
            <NumberFormat
              value={
                Currency == null
                  ? USDRetail
                  : Currency.trim() == 'USD'
                    ? USDRetail
                    : KRWRetail
              }
              displayType={'text'}
              thousandSeparator={true}
              prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
            />
          </td>
          <td>{Math.floor(Discount)}%</td>
        </Fragment>
      ) : (
        <Fragment>
          <td>
            <NumberFormat
              value={Amount / Quantity}
              displayType={'text'}
              thousandSeparator={true}
              prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
            />
          </td>
        </Fragment>
      )}
      <td>
        <NumberFormat
          value={Amount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
        />
      </td>
    </tr>
  );
};
