import React, { Component } from 'react';
import './MenuBar.css';
import { Link } from 'react-router-dom';

class Menu extends Component {
  render() {
    const { icon, menu, path, isSelected, onClick } = this.props;
    const seletedClassName = isSelected ? 'MenuBar__Menu--selected' : null;
    return (
      <div className={`MenuBar__Menu ${seletedClassName}`} onClick={onClick}>
        <Link className="MenuBar__Menu--Linker" to={path}>
          <div className="MenuBar__Menu--icon">{icon}</div>
          <div className="MenuBar__Menu--name">
            <span>{menu}</span>
          </div>
        </Link>
      </div>
    );
  }
}

export default Menu;
