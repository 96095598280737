import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import axios from '../../../util/CustomAxios';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';

import { BallLoader } from '../../General/BallLoader';
import '../../SalesOrder/SalesOrder.css';

class ButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      createdSOID: -1,
      isLoading: false,
    };
    this.submitButtonOnClickHandler =
      this.submitButtonOnClickHandler.bind(this);
  }
  submitButtonOnClickHandler(event, data) {
    for (let error in this.props.SalesOrderFormDataError) {
      console.log(error);
      if (this.props.SalesOrderFormDataError[error] != '') {
        //console.log(this.SalesOrderFormDataError[error])
        return;
      }
    }

    const SalesOrderData = {
      ...this.props.SalesOrderFormData,
      AccountID: this.props.SalesOrderFormData.billingAccountID,
      Status: 4,
      Payment: null, // always null
      DateofShipment: null, // always null
      DateofPickUp: null, // always null
      Currency: this.props.currency.trim(),
      Amount:
        Number(this.props.subTotal) +
        Number(this.props.SalesOrderFormData.ShippingCharge) +
        Number(this.props.SalesOrderFormData.Adjustment),
      Company: this.props.company,
      SODetailes: this.props.SODetailes,
    };

    const invoiceData = {
      inv_num: null,
      so_id: this.props.SOID,
      packing_info: '',
      created_date: this.props.SalesOrderFormData.SOIssueDate,
      isDeleted: null,
      airtable_id: null,
      order_type: this.props.SalesOrderFormData.SOType,
      terms_of_delivery: this.props.SalesOrderFormData.TermsOfDelivery,
      delivery_method: this.props.SalesOrderFormData.DeliveryMethod,
      ship_to: this.props.SalesOrderFormData.ship_to,
      bill_to: this.props.SalesOrderFormData.bill_to,
      shipping_account: this.props.SalesOrderFormData.shippingAccountID,
      billing_account: this.props.SalesOrderFormData.billingAccountID,
      shipping_contact: null,
      billing_contact: null,
      note: '',
      amount:
        Number(this.props.subTotal) +
        Number(this.props.SalesOrderFormData.ShippingCharge) +
        Number(this.props.SalesOrderFormData.Adjustment),
      adjustment: Number(this.props.SalesOrderFormData.Adjustment),
      shipment_id: null,
      pick_up_date: null,
      shipping_date: null,
      arrival_date: null,
      shipping_charge: this.props.SalesOrderFormData.ShippingCharge,
      company: this.props.company,
      currency: this.props.currency,
    };

    const productData = [...this.props.SODetailes];

    this.setState({
      isLoading: true,
    });
    if (this.props.isEdit) {
      axios
        .put(`/Invoice/${this.props.SOID}`, SalesOrderData)
        .then((response) => {
          this.setState({
            isSubmited: true,
            createdInvoiceId: this.props.createdInvoiceId,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false,
          });
          alert('에러가 발생했습니다. 담당자에게 문의해주세요.');
        });
    } else {
      axios
        .post(`/Invoice/${this.props.SOID}`, {
          invoice: invoiceData,
          product: productData,
        })
        .then((response) => {
          this.setState({
            isSubmited: true,
            createdInvoiceId: response.data.createdInvoiceId,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false,
          });
          alert('에러가 발생했습니다. 담당자에게 문의해주세요.');
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? <BallLoader /> : null}
        {this.state.isSubmited ? (
          <Redirect to={`/Invoice/${this.state.createdInvoiceId}`} />
        ) : null}
        <Grid.Row stretched style={this.props.style}>
          <Grid.Column width={10} verticalAlign="middle"></Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <Link
              to={
                this.props.isEdit
                  ? `/SalesOrder/${this.props.SOID}`
                  : '/SalesOrder'
              }
            >
              <Button size="big" className="CancelButton">
                Cancel
              </Button>
            </Link>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <button
              class="big ui button"
              type="submit"
              onClick={this.submitButtonOnClickHandler}
            >
              {this.props.isEdit ? 'Save' : 'Submit'}
            </button>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  currency: !!state.selectedCustomer.billingCustomer.Currency
    ? state.selectedCustomer.billingCustomer.Currency.trim()
    : 'USD',
  selectedCustomer: state.selectedCustomer.billingCustomer,
  subTotal: state.productOrderList.reduce((a, b) => {
    if (
      state.selectedCustomer.billingCustomer.Currency != undefined &&
      state.selectedCustomer.billingCustomer.Currency.trim() == 'KRW'
    ) {
      return (
        a +
        b.product.KRWRetail *
          Number(b.quantity) *
          ((100 - Number(b.discount)) / 100)
      );
    }
    return (
      a +
      b.product.USDRetail *
        Number(b.quantity) *
        ((100 - Number(b.discount)) / 100)
    );
  }, 0),
  SalesOrderFormData: !!getFormValues('SalesOrder')(state)
    ? getFormValues('SalesOrder')(state)
    : null,
  SalesOrderFormDataError: getFormSyncErrors('SalesOrder')(state),

  SODetailes: state.productOrderList.map((productOrder) => {
    return {
      ProductID:
        productOrder.product.ProductID || productOrder.product.ProductId,
      Currency:
        state.selectedCustomer.billingCustomer.Currency == undefined
          ? 'USD'
          : state.selectedCustomer.billingCustomer.Currency.trim(),
      Rate:
        state.selectedCustomer.billingCustomer.Currency == undefined
          ? productOrder.product.USDRetail
          : state.selectedCustomer.billingCustomer.Currency.trim() == 'USD'
            ? productOrder.product.USDRetail
            : productOrder.product.KRWRetail,
      Quantity: productOrder.quantity,
      Discount: productOrder.discount,
      Amount:
        state.selectedCustomer.billingCustomer.Currency == undefined
          ? productOrder.product.USDRetail *
            Number(productOrder.quantity) *
            ((100 - Number(productOrder.discount)) / 100)
          : state.selectedCustomer.billingCustomer.Currency.trim() == 'USD'
            ? productOrder.product.USDRetail *
              Number(productOrder.quantity) *
              ((100 - Number(productOrder.discount)) / 100)
            : productOrder.product.KRWRetail *
              Number(productOrder.quantity) *
              ((100 - Number(productOrder.discount)) / 100),
      IsCustomDiscount: productOrder.isCustomDiscount,
      Serial: null,
      SOIID: productOrder.SOIID,
    };
  }),
  shippingCustomerID: state.shippingCustomerID,
  company: state.company,
}))(ButtonContainer);
