import React from 'react';
import { Table } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

const SalesOrderProductListItem = ({
  SOIID,
  Currency,
  Quantity,
  Discount,
  Amount,
  ProductCode,
  Model,
  Color,
  Voltage,
  HDD,
  SSD,
  Item,
  USDRetail,
  KRWRetail,
  Description,
  HS_CODE,
  serials,
  customDescription,
  onCustomDescription,
  sku,
}) => {
  const fobPrice = Amount / Quantity;
  return (
    <Table.Row>
      <Table.Cell id="ListItems" className="ListItem">
        <p style={{ fontWeight: 900 }}>{ProductCode.trim()}</p>

        {Model.trim() == 'CS_Parts' ? (
          <p style={{ margin: 0, fontSize: '12px' }}>
            SKU:{' '}
            {sku ? sku : `${Model.trim()}-${Item != null ? Item.trim() : ''}`}
          </p>
        ) : (
          <p style={{ margin: 0, fontSize: '12px' }}>
            SKU:{' '}
            {sku
              ? sku
              : `${Model.trim()}-${Color.trim()}-${Voltage.trim()}-${HDD.trim()}-${SSD.trim()}`}
          </p>
        )}
        <p style={{ margin: 0, fontSize: '12px' }}>
          {Description == undefined ? '' : Description.trim()}
          {Description == undefined || HS_CODE == undefined ? '' : ' / '}
          {HS_CODE == undefined ? '' : HS_CODE.trim()}
        </p>
        <p style={{ margin: 0, fontSize: '12px' }}>
          {serials == undefined
            ? ''
            : serials.map((serial) => serial.Serial).join(', ')}
        </p>
        <input value={customDescription} onChange={onCustomDescription} />
      </Table.Cell>
      <Table.Cell
        id="ListItems"
        className="ListItem"
        style={{ textAlign: 'right' }}
      >
        <NumberFormat
          value={Quantity}
          displayType={'text'}
          thousandSeparator={true}
        />
      </Table.Cell>
      <Table.Cell
        id="ListItems"
        className="ListItem"
        style={{ textAlign: 'right' }}
      >
        <NumberFormat
          value={
            Currency == null
              ? USDRetail
              : Currency.trim() == 'USD'
                ? USDRetail
                : KRWRetail
          }
          displayType={'text'}
          thousandSeparator={true}
          prefix={Currency == 'KRW' ? '₩' : '$'}
        />
      </Table.Cell>
      <Table.Cell
        id="ListItems"
        className="ListItem"
        style={{ textAlign: 'right' }}
      >
        <NumberFormat
          value={Discount}
          displayType={'text'}
          thousandSeparator={true}
        />
      </Table.Cell>
      <Table.Cell
        id="ListItems"
        className="ListItem"
        style={{ textAlign: 'right', paddingRight: '14px' }}
      >
        <NumberFormat
          value={fobPrice}
          displayType={'text'}
          thousandSeparator={true}
          prefix={Currency == 'KRW' ? '₩' : '$'}
        />
      </Table.Cell>
      <Table.Cell
        id="ListItems"
        className="ListItem"
        style={{ textAlign: 'right', paddingRight: '14px' }}
      >
        <NumberFormat
          value={Amount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={Currency == 'KRW' ? '₩' : '$'}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default SalesOrderProductListItem;
