import React from 'react';
import AurenderLogo from '../../../images/aurender_logo_color.png';
import '../../SalesOrder/SalesOrder.css';
import NumberFormat from 'react-number-format';
import SalesOrderPDFProductListItem from '../../SalesOrder/presenters/SalesOrderPDFProductListItem';
import CustomerBillingAdress from '../../SalesOrder/presenters/CustomerBillingAdress';
import CustomerShippingAdress from '../../SalesOrder/presenters/CustomerShippingAdress';
import CustomerContact from '../../SalesOrder/presenters/CustomerContact';
import CustomerAddress from '../../SalesOrder/presenters/CustomerAddress';

export default (props) => {
  const itemList = props.items.map((item, index) => (
    <SalesOrderPDFProductListItem
      {...item}
      index={index}
      isShowDiscount={props.isShowDiscount}
    />
  ));
  const Rate = props.items.reduce(function (a, b) {
    return (
      a +
      (b.Currency == null
        ? b.USDRetail
        : b.Currency.trim() == 'USD'
          ? b.USDRetail
          : b.KRWRetail)
    );
  }, 0);
  const Amount = props.items.reduce(function (a, b) {
    return a + b.Amount;
  }, 0);
  const Currency =
    props.items[0] == null
      ? 'USD'
      : props.items[0].Currency == null
        ? 'USD'
        : props.items[0].Currency;

  return (
    <div className="SalesOrderPDF__Container" id="SalesOrderPDF" {...props}>
      <header className="SalesOrderPDF__Header">
        <div className="SalesOrderPDF__Header--Company">
          <img
            src={AurenderLogo}
            className="SalesOrderPDF__Header--CompanyLogo"
          />
          <br />
          <strong className="SalesOrderPDF__Header--CompanyName">
            Aurender America Inc.
          </strong>
          <p className="SalesOrderPDF__Header--CompanyAddressMultiLine">
            20381 Lake Forest Dr.
          </p>
          <p className="SalesOrderPDF__Header--CompanyAddressMultiLine">
            Suite B3
          </p>
          <p className="SalesOrderPDF__Header--CompanyAddressMultiLine">
            Lake Forest, CA 92630
          </p>
          <p className="SalesOrderPDF__Header--CompanyAddressMultiLine">
            888-367-0840
          </p>
          <p className="SalesOrderPDF__Header--CompanyAddressMultiLine">
            sales@aurender.com
          </p>
        </div>
        <div className="SalesOrderPDF__Header--SalesOrderInfo">
          <strong className="SalesOrderPDF__Header--SalesOrder">Invoice</strong>
          <h4 className="SalesOrderPDF__Header--SalesOrderNumber">
            Invoice# {props.detail.inv_num}
          </h4>
        </div>
      </header>
      <article className="SalesOrderPDF__Article">
        <section className="SalesOrderPDF__Article--section">
          <CustomerAddress
            header="Billing Address"
            accountName={
              props.detail.BillingAccountName ||
              props.detail.BillingCustomerName
            }
            address={props.detail.BillingAddress}
            state={props.detail.BillingState}
            city={props.detail.BillingCity}
            postal_code={props.detail.BillingPostalCode}
            countryName={props.detail.BillingCountry}
            isEditable={false}
          />
          <CustomerContact
            header="Billing Contact"
            name={props.detail.BillingContactName}
            email={props.detail.BillingContactEmail}
            phone={props.detail.BillingContactPhone}
            mobile_phone={props.detail.BillingContactMobilePhone}
          />
        </section>
        <section className="SalesOrderPDF__Article--ShipToContainer SalesOrderPDF__Article--section">
          <CustomerAddress
            header="Shipping Address"
            accountName={
              props.detail.ShippingAccountName ||
              props.detail.ShippingCustomerName
            }
            address={props.detail.ShippingAddress}
            state={props.detail.ShippingState}
            city={props.detail.ShippingCity}
            postal_code={props.detail.ShippingPostalCode}
            countryName={props.detail.ShippingCountry}
            isEditable={false}
          />
          <CustomerContact
            header="Shipping Contact"
            name={props.detail.ShippingContactName}
            email={props.detail.ShippingContactEmail}
            phone={props.detail.ShippingContactPhone}
            mobile_phone={props.detail.ShippingContactMobilePhone}
          />
        </section>
        <section className="SalesOrderPDF__Article--SalesOrderTypes">
          <table className="SalesOrderPDF__Article--SalesOrderTypesTable">
            <tbody>
              <tr>
                <td className="SalesOrderPDF__Article--SalesOrderTypesName">
                  Invoice Date :
                </td>
                <td className="SalesOrderPDF__Article--SalesOrderTypesValue">
                  {props.detail.created_date}
                </td>
              </tr>
              <tr>
                <td className="SalesOrderPDF__Article--SalesOrderTypesName">
                  Delivery Method :
                </td>
                <td className="SalesOrderPDF__Article--SalesOrderTypesValue">
                  {props.detail.DeliveryMethod}
                </td>
              </tr>{' '}
              <tr>
                <td className="SalesOrderPDF__Article--SalesOrderTypesName">
                  Terms of Delivery :
                </td>
                <td className="SalesOrderPDF__Article--SalesOrderTypesValue">
                  {props.detail.TermsOfDelivery}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="SalesOrderPDF__Article--Product">
          <table className="SalesOrderPDF__Article--ProductTable">
            <thead>
              <tr>
                <th>#</th>
                <th>Item & Description</th>
                <th>Qty</th>
                <th>List Price</th>
                {props.isShowDiscount ? <th>Discount</th> : null}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{itemList}</tbody>
          </table>
          <div className="SalesOrderPDF__Article--Total">
            <table className="SalesOrderPDF__Article--TotalTable">
              <tbody>
                {props.isShowDiscount ? (
                  <tr>
                    <td>List Price Total</td>
                    <td>
                      <NumberFormat
                        value={Rate}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>

                    <td>Amount Total</td>
                    <td>
                      <NumberFormat
                        value={Amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                ) : null}

                {Math.floor(props.detail.ShippingCharge) == 0 ? null : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Shipping Charge</td>
                    <td>
                      <NumberFormat
                        value={props.detail.ShippingCharge}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                )}
                {Math.floor(props.detail.Adjustment) == 0 ? null : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Adjustment</td>
                    <td>
                      <NumberFormat
                        value={props.detail.Adjustment}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                      />
                    </td>
                  </tr>
                )}
                <tr className="SalesOrderPDF__Article--TotalTableTotal">
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>
                    <NumberFormat
                      value={props.detail.Amount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={Currency.trim() == 'KRW' ? '₩' : '$'}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {props.detail.note ? (
            <section>
              <h4>Note</h4>
              <div className="SalesOrderPDF__BackAccountContainer">
                {props.detail.note.split('\n').map((text) => (
                  <p>{text}</p>
                ))}
              </div>
            </section>
          ) : null}
          <section>
            <h4>Packing Info</h4>
            <div className="SalesOrderPDF__BackAccountContainer">
              {props.detail.packing_info.split('\n').map((text) => (
                <p>{text}</p>
              ))}
            </div>
          </section>
        </section>

        <section>
          <h4>Payment Instructions</h4>
          <div className="SalesOrderPDF__BackAccountContainer">
            <p>All orders prepaid to:</p>
            <p>Routing number : 121000358</p>
            <p>Bank account number : 3250 5711 5490</p>
            <p>Bank name : Bank of America</p>
            <p>Bank account holder name : AURENDER AMERICA INC.</p>
            <br />
            <p>For International Transfer:</p>
            <p>SWIFT code: BOFAUS3N</p>
            <p>Routing transit number: 026009593</p>
            <p>
              Bank address: Bank of America, N.A., 222 Broadway, New York, NY
              10038
            </p>
            <p>Bank account number: 3250 5711 5490</p>
            <p>Beneficiary name: AURENDER AMERICA INC.</p>
            <br />
            <p>Physical Checks can be mailed to:</p>
            <p>Aurender America Inc.</p>
            <p>20381 Lake Forest Dr, STE B3, Lake Forest, CA 92630</p>
            <p>Pay to the Order of: Aurender America Inc.</p>
            {/* <p>ph 714-743-5799</p> */}
          </div>
        </section>
      </article>
    </div>
  );
};
