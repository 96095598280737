import React, { Component, ReactFragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CountryWithFlag from './CountryWithFlag';
import AddressListContainer from '../Container/AddressListContainer';
import AccountInfo from './AccountInfo';
import { matchSorter } from 'match-sorter';
import CountrySimpleDropdown from './CountrySimpleDropdown';
import AccountDetail from '../AccountDetail';
import ModalWrapper from '../../General/ModalWrapper';
import { Button, Header, Image, Modal } from 'semantic-ui-react';
import { ReactTableDefaults } from 'react-table';

Object.assign(ReactTableDefaults, {
  NoDataComponent: () => (
    <span
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#CACBCD',
        padding: '10px',
        borderRadius: '.28571429rem',
        boxShadow: '0 2px 15px 0 rgba(0,0,0,0.15)',
      }}
    >
      No Data
    </span>
  ),
});

export default class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
      pageIndex: 0,
    };
  }

  render() {
    const emptyChangedList = this.props.accountList.map((account) => {
      Object.keys(account).map((key) =>
        account[key] == '' ? (account[key] = 'EMPTY') : null
      );
      return account;
    });
    return (
      <div>
        <ReactTable
          data={emptyChangedList}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          expanded={this.state.expanded[this.state.pageIndex]}
          noDataText="No rows found"
          columns={[
            {
              Header: 'Account',
              columns: [
                {
                  Header: 'Name',
                  accessor: 'Name',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['Name'],
                    }),
                  filterAll: true,
                  Cell: (row) => (
                    // <Modal size="Huge" trigger={<span>{row.value}</span>}>
                    //   <Modal.Content>
                    //     <AccountDetailContainer {...row.original} />
                    //   </Modal.Content>
                    // </Modal>
                    <ModalWrapper
                      Trigger={(props) => <span {...props}>{row.value}</span>}
                      Contents={<AccountDetail {...row.original} />}
                    />
                  ),
                },
                {
                  Header: 'Country',
                  accessor: 'countryName',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['countryName'],
                    }),
                  Filter: ({ filter, onChange }) => (
                    <CountrySimpleDropdown
                      style={{ display: 'block' }}
                      list={this.props.countryList}
                      value={filter ? filter.value : ''}
                      name="country"
                      onChange={(event) => onChange(event.target.value.Name)}
                    />
                  ),
                  filterAll: true,
                  Cell: (row) => <CountryWithFlag country={row.value} />,
                  width: 200,
                },
                {
                  Header: 'Type',
                  accessor: 'Type',
                  filterMethod: (filter, rows) => {
                    return rows.filter((row) => {
                      if (filter.value === 'all') {
                        return true;
                      } else if (
                        filter.value.trim() === row[filter.id].trim()
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    // matchSorter(rows, filter.value, { keys: ["Type"] })
                  },
                  filterAll: true,
                  width: 200,
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="Dealer">Dealer</option>
                      <option value="Distributor">Distributor</option>
                      <option value="End User">End User</option>
                      <option value="Industry Partner">Industry Partner</option>
                      <option value="Reviewer">Reviewer</option>
                      <option value="Customer">Customer</option>
                    </select>
                  ),
                },
                {
                  Header: 'State',
                  accessor: 'state',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['state'],
                    }),
                  filterAll: true,
                  width: 200,
                },
                {
                  Header: 'City',
                  accessor: 'city',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['city'],
                    }),
                  filterAll: true,
                  width: 200,
                },
                {
                  Header: 'Address',
                  accessor: 'address',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['address'],
                    }),
                  filterAll: true,
                  width: 200,
                },
                {
                  Header: 'Postal Code',
                  accessor: 'postal_code',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['postal_code'],
                    }),
                  filterAll: true,
                  width: 200,
                },
                {
                  Header: 'Ship = Bill',
                  accessor: 'is_same_address',
                  filterMethod: (filter, rows) => {
                    return rows.filter((row) => {
                      if (filter.value === 'all') {
                        return true;
                      } else if (
                        filter.value.trim() === row[filter.id].trim()
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    });
                    // matchSorter(rows, filter.value, { keys: ["Type"] })
                  },
                  filterAll: true,
                  width: 200,
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: '100%' }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">Show All</option>
                      <option value="Same">Same</option>
                      <option value="Different">Different</option>
                    </select>
                  ),
                },
              ],
            },
          ]}
          // SubComponent={row => {
          //   return (
          //     <AccountInfo {...row.original} />
          //   );
          // }}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return {
              style: {
                overflow: 'initial',
              },
            };
          }}
          // onExpandedChange={(newExpanded, index, event) => {
          //   this.setState({
          //     expanded: {
          //       ...this.state.expanded,
          //       [this.state.pageIndex]: newExpanded
          //     }
          //   })
          // }}
          onPageChange={(pageIndex) => {
            this.setState({
              pageIndex: pageIndex,
            });
          }}
        />
      </div>
    );
  }
}
