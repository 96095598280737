import React, { Component, PropTypes } from 'react';
import './Loader.css';

export class FingurLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="finger-loading-container">
        <div class="loading">
          <div className="finger finger-1">
            <div className="finger-item">
              <span></span>
              <i></i>
            </div>
          </div>
          <div className="finger finger-2">
            <div className="finger-item">
              <span></span>
              <i></i>
            </div>
          </div>
          <div className="finger finger-3">
            <div className="finger-item">
              <span></span>
              <i></i>
            </div>
          </div>
          <div className="finger finger-4">
            <div className="finger-item">
              <span></span>
              <i></i>
            </div>
          </div>
          <div className="last-finger">
            <div className="last-finger-item">
              <i></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
