import React, { Component } from 'react';

import withRequireLogin from '../../HOCs/withRequireLogin';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { makeData, Logo, Tips } from './Utils';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container/Container';
import AccountListContainer from './Container/AccountListContainer';
import { Header, Button, Checkbox } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: makeData(),
    };
  }

  render() {
    const { data } = this.state;
    return (
      <div style={{ width: '90vw', margin: '0 auto' }}>
        <Header as="h1" dividing>
          Account
          <Link to="/NewAccount">
            <Button floated="right">New</Button>
          </Link>
        </Header>
        <br />
        <AccountListContainer />
      </div>
    );
  }
}

export default withRequireLogin(AccountList);
