import React, { Component, PropTypes } from 'react';
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Input,
  Icon,
} from 'semantic-ui-react';
import axios from '../../../util/CustomAxios';
import dateConverter from '../../../util/dateToYYYY-MM-DD';
import StyledSalesOrderPaidStatusDropdown from './StyledSalesOrderPaidStatusDropdown';

class SalesOrderPaidStatusManager extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.props.onChange(value);
  }

  render() {
    const inputObj = {
      onChange: this.onChange,
      value: this.props.value,
    };
    return (
      <Grid.Row stretched>
        <Grid.Column width={8} verticalAlign="middle">
          <Header>Paid Status</Header>
          <StyledSalesOrderPaidStatusDropdown input={inputObj} />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default SalesOrderPaidStatusManager;
