import React, { Component } from 'react';
import {
  Label,
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Radio,
  Form,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import axios from '../../util/CustomAxios';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import NumberFormat from 'react-number-format';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIsSODatesUpdateComplete } from '../../action/salesOrder';

import SalesOrderProductList from '../SalesOrder/presenters/SalesOrderProductList';

import '../SalesOrder/SalesOrder.css';

import ShippingForm from '../SalesOrder/containers/ShippingForm';

import InvoicePDFView from './presenters/InvoicePDFContainer';
import InvoicePDFViewAA from './presenters/InvoicePDFViewAA';

import ReactToPrint from 'react-to-print';
import CustomerContact from '../SalesOrder/presenters/CustomerContact';
import CustomerAddress from '../SalesOrder/presenters/CustomerAddress';

const propTypes = {};

const defaultProps = {};

class InvoiceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SOID: null,
      detail: {
        inv_num: 'INV-0000',
        SOIssueDate: new Date().toISOString().substring(0, 10),
        SalesOrderType: '',
        TermsOfDelivery: '',
        DeliveryMethod: '',
        packing_info: '',
      },
      items: [],
      isPDFView: false,
      soDates: {
        DateofPickUp: null,
        DateofShipment: null,
      },
      isFTA: true,
      isShowShippingForm: false,
      isShowDiscount: false,
      isConfirmed: true,
    };
    this.onPDFToggleClickHandler = this.onPDFToggleClickHandler.bind(this);
    this.printDocument = this.printDocument.bind(this);
  }

  printDocument(isFTA) {
    this.setState(
      {
        isFTA: isFTA,
      },
      () => {
        const input = document.getElementById('SalesOrderPDF');
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
          // pdf.output('dataurlnewwindow');
          pdf.save(`${this.state.detail.inv_num.trim()}.pdf`);
        });
      }
    );
  }

  pdfToHTML() {
    var pdf = new jsPDF('p', 'pt', 'letter');
    var source = document.getElementById('SalesOrderPDF');
    var specialElementHandlers = {
      '#bypassme': function (element, renderer) {
        return true;
      },
    };

    var margins = {
      top: 50,
      left: 60,
      width: 545,
    };

    pdf.fromHTML(
      source, // HTML string or DOM elem ref.
      margins.left, // x coord
      margins.top, // y coord
      {
        width: margins.width, // max width of content on PDF
        elementHandlers: specialElementHandlers,
      },
      function (dispose) {
        // dispose: object with X, Y of the last line add to the PDF
        // this allow the insertion of new lines after html
        pdf.save('html2pdf.pdf');
      }
    );
  }

  componentWillMount() {
    let SOID = this.props.SOID;
    console.log(this.props.SOID);
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }
    this.setState({
      SOID: SOID,
    });

    console.log(SOID);
    axios
      .get(`/Invoice/${SOID}`)
      .then((response) => {
        console.log(response.data);
        // response.data[0].Currency = response.data[0].Currency.trim();
        this.setState({
          detail: {
            inv_num: response.data.inv_num,
            Amount: response.data.amount,
            created_date: new Date(response.data.created_date)
              .toISOString()
              .substring(0, 10),
            SalesOrderType: response.data.order_type,
            TermsOfDelivery: response.data.terms_of_delivery,
            DeliveryMethod: response.data.delivery_method,
            packing_info: response.data.packing_info,
            BillingAccountName: response.data.billing_account_name,
            BillingAddress: response.data.billing_address,
            BillingState: response.data.billing_state,
            BillingPostalCode: response.data.billing_postal_code,
            BillingCity: response.data.billing_city,
            ShippingAccountName: response.data.shipping_account_name,
            ShippingAddress: response.data.shipping_address,
            ShippingState: response.data.shipping_state,
            ShippingPostalCode: response.data.shipping_postal_code,
            ShippingCity: response.data.shipping_city,
            note: response.data.note,
            ShippingCharge: response.data.shipping_charge,
            Adjustment: response.data.adjustment,
            BillingContactName: response.data.billing_contact_name,
            BillingContactPhone: response.data.billing_contact_phone,
            BillingContactMobilePhone:
              response.data.billing_contact_mobile_phone,
            BillingContactEmail: response.data.billing_contact_email,
            ShippingContactName: response.data.shipping_contact_name,
            ShippingContactPhone: response.data.shipping_contact_phone,
            ShippingContactMobilePhone:
              response.data.shipping_contact_mobile_phone,
            ShippingContactEmail: response.data.shipping_contact_email,
            ShippingCountry: response.data.shipping_country,
            BillingCountry: response.data.billing_country,
            Currency: response.data.currency,
            company: response.data.company,
          },
          isConfirmed: response.data.airtable_id != null,
        });

        if (response.data) {
          document.title = `${response.data.inv_num}_${response.data.shipping_account_name}`;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(`/Invoice/${SOID}/items`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          items: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(`/SalesOrder/${SOID}/Dates`)
      .then((response) => {
        console.log(response.data[0]);
        this.setState({
          soDates: {
            DateofPickUp:
              response.data[0].DateofPickUp == null
                ? null
                : new Date(response.data[0].DateofPickUp)
                    .toISOString()
                    .substring(0, 10),
            DateofShipment:
              response.data[0].DateofShipment == null
                ? null
                : new Date(response.data[0].DateofShipment)
                    .toISOString()
                    .substring(0, 10),
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.isSODatesUpdateComplete);
    if (nextProps.isSODatesUpdateComplete) {
      axios
        .get(`/SalesOrder/${this.props.SOID}/Dates`)
        .then((response) => {
          console.log(response.data[0]);
          this.setState({
            soDates: {
              DateofPickUp:
                response.data[0].DateofPickUp == null
                  ? null
                  : new Date(response.data[0].DateofPickUp)
                      .toISOString()
                      .substring(0, 10),
              DateofShipment:
                response.data[0].DateofShipment == null
                  ? null
                  : new Date(response.data[0].DateofShipment)
                      .toISOString()
                      .substring(0, 10),
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      nextProps.setIsSODatesUpdateComplete(false);
    }
  }

  onPDFToggleClickHandler() {
    this.setState({
      isPDFView: !this.state.isPDFView,
    });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  changePackingInfo = async () => {
    this.setState({
      isInvoiceLoading: true,
    });
    const result = await axios.post(
      `/Invoice/change_packing/${this.state.SOID}`,
      {
        packing_info: this.state.detail.packing_info,
      }
    );
    this.props.history.push(`/`);
    this.props.history.push(`Invoice/${this.state.SOID}`);
  };

  changeNote = async () => {
    this.setState({
      isInvoiceLoading: true,
    });
    const result = await axios.patch(`/Invoice/${this.state.SOID}`, {
      note: this.state.detail.note,
    });
    this.props.history.push(`/`);
    this.props.history.push(`Invoice/${this.state.SOID}`);
  };

  onUpdateSerialClick = async (e) => {
    try {
      const result = await axios.patch(`/Invoice/Serial/${this.state.SOID}`);
      this.setState({
        items: result.data,
      });
      if (result.status == 200) {
        this.setState({
          isConfirmed: true,
        });
        alert('DONE!');
      }
    } catch (err) {
      console.error(err);
      alert(
        err.response.data.message ||
          '요청에 실패했습니다. 관리자에게 문의해주세요.'
      );
    }
  };

  onPackingInfoChange = (e) => {
    this.setState({
      detail: {
        ...this.state.detail,
        packing_info: e.target.value,
      },
    });
  };

  onNoteChange = (e) => {
    this.setState({
      detail: {
        ...this.state.detail,
        note: e.target.value,
      },
    });
  };

  onShowDiscountToggle = (e) => {
    this.setState({
      isShowDiscount: !this.state.isShowDiscount,
    });
  };

  onFTAToggle = (e) => {
    this.setState({
      isFTA: !this.state.isFTA,
    });
  };

  onDeleteButtonClick = async (e) => {
    let SOID = this.props.SOID;
    console.log(this.props.SOID);
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }
    let result = await axios.delete(`/Invoice/${SOID}`);
    window.location.href = '/SalesOrder';
  };

  onToAirtableButtonClick = async (e) => {
    let SOID = this.props.SOID;
    console.log(this.props.SOID);
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }
    this.setState({
      isConfirming: true,
    });
    try {
      let result = await axios.post(`/Invoice/confirm/${SOID}`);
      if (result.status == 200) {
        this.setState({
          isConfirmed: true,
        });
        alert('DONE!');
      }
    } catch (err) {
      console.error(err);
      alert(
        err.response.data.message ||
          '요청에 실패했습니다. 관리자에게 문의해주세요.'
      );
    } finally {
      this.setState({
        isConfirming: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Container>
          <Dimmer active={this.state.isConfirming}>
            <Loader>Confirming...</Loader>
          </Dimmer>
          <Header as="h2" dividing>
            <Header.Content>{this.state.detail.inv_num.trim()}</Header.Content>
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              Switch PDF{' '}
            </span>
            <Radio
              onClick={this.onPDFToggleClickHandler}
              floated="right"
              toggle
              checked={this.state.isPDFView}
            />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              Show Discount{' '}
            </span>
            <Radio
              onClick={this.onShowDiscountToggle}
              floated="right"
              toggle
              checked={this.state.isShowDiscount}
            />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              FTA{' '}
            </span>
            <Radio
              onClick={this.onFTAToggle}
              floated="right"
              toggle
              checked={this.state.isFTA}
            />
          </Header>
          <Header as="h2">
            <Header.Content>
              Invoice
              {this.state.detail.test ? (
                <Label
                  style={{
                    background: '#F05252',
                    color: 'white',
                  }}
                >
                  test
                </Label>
              ) : null}
              <Header.Subheader>#{this.state.detail.inv_num}</Header.Subheader>
            </Header.Content>
            <Button.Group floated="right">
              {this.state.isConfirmed ? null : (
                <Button onClick={this.onToAirtableButtonClick}>Confirm</Button>
              )}

              <Button onClick={this.onDeleteButtonClick}>Delete</Button>
              {this.state.isConfirmed ? (
                <Button onClick={this.onUpdateSerialClick}>Update</Button>
              ) : null}
              <Button
                onClick={() => {
                  // open DHL shipper
                  window.location.href = `bpr-desktop://ship/${this.state.SOID}?token=${localStorage.APIToken}`;
                }}
              >
                Ship
              </Button>
              <ReactToPrint
                trigger={() => <Button>PDF</Button>}
                content={() => this.pdfRef}
                pageStyle={`@page { size: auto;  margin: 20mm; }`}
              />
            </Button.Group>
          </Header>
          <Grid
            columns="equal"
            stackable
            style={this.state.isPDFView ? { display: 'none' } : {}}
            detail={this.state.detail}
          >
            <Grid.Row stretched />
            {this.state.detail == null ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.created_date ? (
                    <div>
                      <h5 className="ItemDetail__Header">Invoice Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.created_date}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.SalesOrderType ? (
                    <div>
                      <h5 className="ItemDetail__Header">Sales Order Type</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.SalesOrderType}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.soDates.DateofPickUp != null ||
            this.state.soDates.DateofShipment != null ? (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.soDates.DateofPickUp != null ? (
                    <div>
                      <h5 className="ItemDetail__Header">Pick Up Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.soDates.DateofPickUp}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.soDates.DateofShipment != null ? (
                    <div>
                      <h5 className="ItemDetail__Header">Shipment Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.soDates.DateofShipment}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {this.state.detail == null ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.TermsOfDelivery ? (
                    <div>
                      <h5 className="ItemDetail__Header">Terms Of Delivery</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.TermsOfDelivery}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.DeliveryMethod ? (
                    <div>
                      <h5 className="ItemDetail__Header">Delivery Method</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.DeliveryMethod}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column width={8} verticalAlign="top">
                <CustomerAddress
                  header="Billing Address"
                  accountName={
                    this.state.detail.BillingAccountName ||
                    this.state.detail.BillingCustomerName
                  }
                  address={this.state.detail.BillingAddress}
                  state={this.state.detail.BillingState}
                  city={this.state.detail.BillingCity}
                  postal_code={this.state.detail.BillingPostalCode}
                  countryName={this.state.detail.BillingCountry}
                  isEditable={false}
                />
                <CustomerContact
                  header="Billing Contact"
                  name={this.state.detail.BillingContactName}
                  email={this.state.detail.BillingContactEmail}
                  phone={this.state.detail.BillingContactPhone}
                  mobile_phone={this.state.detail.BillingContactMobilePhone}
                />
              </Grid.Column>
              <Grid.Column width={8} verticalAlign="top">
                <CustomerAddress
                  header="Shipping Address"
                  accountName={
                    this.state.detail.ShippingAccountName ||
                    this.state.detail.ShippingCustomerName
                  }
                  address={this.state.detail.ShippingAddress}
                  state={this.state.detail.ShippingState}
                  city={this.state.detail.ShippingCity}
                  postal_code={this.state.detail.ShippingPostalCode}
                  countryName={this.state.detail.ShippingCountry}
                  isEditable={false}
                />
                <CustomerContact
                  header="Shipping Contact"
                  name={this.state.detail.ShippingContactName}
                  email={this.state.detail.ShippingContactEmail}
                  phone={this.state.detail.ShippingContactPhone}
                  mobile_phone={this.state.detail.ShippingContactMobilePhone}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="top">
                {this.state.items == null ? null : (
                  <div>
                    <SalesOrderProductList
                      items={this.state.items.map((item, index) => ({
                        ...item,
                        onCustomDescription: (e) => {
                          const tmpArray = [...this.state.items];
                          tmpArray[index].customDescription = e.target.value;
                          this.setState({
                            items: tmpArray,
                          });
                        },
                      }))}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>

            {this.state.detail.Adjustment == null ||
            this.state.detail.Adjustment == 0 ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h5 className="ItemDetail__Header">Adjustment</h5>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h4
                    style={{
                      textAlign: 'right',
                      marginRight: '14px',
                    }}
                  >
                    <NumberFormat
                      value={this.state.detail.Adjustment}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                    />
                  </h4>
                </Grid.Column>
              </Grid.Row>
            )}

            {this.state.detail.ShippingCharge == null ||
            this.state.detail.ShippingCharge == 0 ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h5 className="ItemDetail__Header">Shipping Charge</h5>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h4
                    style={{
                      textAlign: 'right',
                      marginRight: '14px',
                    }}
                  >
                    <NumberFormat
                      value={this.state.detail.ShippingCharge}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                    />
                  </h4>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row stretched>
              <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <h5 className="ItemDetail__Header">Total</h5>
              </Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <h4
                  style={{
                    textAlign: 'right',
                    marginRight: '14px',
                  }}
                >
                  <NumberFormat
                    value={this.state.detail.Amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                  />
                </h4>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="middle">
                <Form>
                  <Form.TextArea
                    style={{ height: '150px' }}
                    label="Note"
                    onChange={this.onNoteChange}
                    value={this.state.detail.note}
                  />
                  <Form.Button onClick={this.changeNote}>Change</Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="middle">
                <Form>
                  <Form.TextArea
                    style={{ height: '150px' }}
                    label="Packing Info"
                    onChange={this.onPackingInfoChange}
                    value={this.state.detail.packing_info}
                  />
                  <Form.Button onClick={this.changePackingInfo}>
                    Change
                  </Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <div>
          <Modal
            open={this.state.isShowShippingForm}
            onClose={this.handleCloseModal}
            closeIcon={10}
          >
            <br />
            <br />
            <ShippingForm />
          </Modal>
        </div>
        <div ref={(ref) => (this.pdfRef = ref)}>
          {this.state.detail.company === 'Aurender America Inc.' ? (
            <InvoicePDFViewAA
              isFTA={this.state.isFTA}
              isShowDiscount={this.state.isShowDiscount}
              style={this.state.isPDFView ? {} : { zIndex: '-1' }}
              detail={this.state.detail}
              items={this.state.items}
            />
          ) : (
            <InvoicePDFView
              isFTA={this.state.isFTA}
              isShowDiscount={this.state.isShowDiscount}
              style={this.state.isPDFView ? {} : { zIndex: '-1' }}
              detail={this.state.detail}
              items={this.state.items}
            />
          )}
        </div>
      </div>
    );
  }
}

export default InvoiceItem = connect(
  (state) => ({
    isSODatesUpdateComplete: state.isSODatesUpdateComplete,
  }),
  (dispatch) => ({
    setIsSODatesUpdateComplete: bindActionCreators(
      setIsSODatesUpdateComplete,
      dispatch
    ),
  })
)(InvoiceItem);
