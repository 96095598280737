import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAccountList } from '../../../action/account';
import AccountList from '../Presenter/AccountList';
import { getCountryList } from '../../../action/Country';
import { getStateList } from '../../../action/State';

import {
  Grid,
  Container,
  Header,
  Button,
  Dropdown,
  Input,
  Checkbox,
} from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { Field, Fields, reduxForm, getFormValues } from 'redux-form';

import ContactListContainer from './ContactListContainer';
import AddressListContainer from './AddressListContainer';
import AccountName from '../Presenter/AccountName';
import axios from '../../../util/CustomAxios';
import withGetArrayData from '../../../HOCs/withGetArrayData';

class NewAccountContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      AccountID: null,
      TypeData: [],
      CountryData: [],
      name: '',
      type: '',
      country: '',
      active: true,
      isError: false,
      isSubmited: false,
    };
    this.onNameInputChangeHandler = this.onNameInputChangeHandler.bind(this);
    this.onTypeDropDownChangeHandler =
      this.onTypeDropDownChangeHandler.bind(this);
    this.onCountryDropDownChangeHandler =
      this.onCountryDropDownChangeHandler.bind(this);
    this.makeNewAccount = this.makeNewAccount.bind(this);
    this.deleteNewAccount = this.deleteNewAccount.bind(this);
    this.submitButtonOnClickHandler =
      this.submitButtonOnClickHandler.bind(this);
  }
  onNameInputChangeHandler(event, data) {
    this.setState({
      name: data.value,
    });
  }
  onTypeDropDownChangeHandler(event, data) {
    this.setState({
      type: data,
    });
  }
  onCountryDropDownChangeHandler(event, data) {
    this.setState({
      country: data,
    });
  }

  componentWillMount() {
    this.makeNewAccount();
    this.props.getCountryList();
    this.props.getStateList();

    axios
      .get('/getAccountTypeList')
      .then((response) => {
        this.setState({
          TypeData: response.data.map((item) => {
            return {
              key: item.TypeID,
              text: item.Type,
              value: item.TypeID,
            };
          }),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  componentDidMount() {
    if (this.state.AccountID == null) this.makeNewAccount();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.countryList != nextProps.countryList) {
      this.setState({
        CountryData: nextProps.countryList.map((item) => {
          return {
            key: item.CountryID,
            text: item.Name,
            value: item.CountryID,
          };
        }),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let result = true;
    return result;
  }

  makeNewAccount() {
    axios
      .get('/GetNewAccountID')
      .then((response) => {
        if (response.data.length == 0) {
          axios
            .post('/NewAccount')
            .then((response) => {
              if (response.status == 200) {
                axios
                  .get('/GetNewAccountID')
                  .then((response) => {
                    this.setState({
                      AccountID: response.data[0].AccountID,
                    });
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          this.setState({
            AccountID: response.data[0].AccountID,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  deleteNewAccount() {
    axios
      .post(`/DeleteAccountMadeAutomatically/${this.state.AccountID}`)
      .then((response) => {})
      .catch(function (error) {
        console.log(error);
      });
  }
  submitButtonOnClickHandler() {
    if (this.state.type == '' || this.state.name == '') {
      console.log('error');
      this.setState({
        isError: true,
      });
    } else if (
      this.props.addressList[this.state.AccountID] == undefined ||
      this.props.addressList[this.state.AccountID].length == 0
    ) {
      alert('You have to insert at least one address');
    } else {
      let baseAddress = 0;
      let currency = '';
      let billing = 0;
      let shipping = 0;
      axios
        .get(`/NewAccountCountryInfo/${this.state.AccountID}`)
        .then((response) => {
          let baseAddress = response.data[0].id;
          let currency = this.state.country.value == 42 ? 'KRW' : 'USD';
          axios
            .get(`/NewAccountAddressInfo/${this.state.AccountID}`)
            .then((response) => {
              console.log(response.data);
              let billing =
                response.data[0].default_billing_address == null
                  ? baseAddress
                  : response.data[0].default_billing_address;
              let shipping =
                response.data[0].default_shipping_address == null
                  ? baseAddress
                  : response.data[0].default_shipping_address;
              axios
                .put(`/UpdateNewAccount/${this.state.AccountID}`, {
                  Name: this.state.name,
                  Type: this.state.type.value,
                  Active: this.state.active ? 1 : 0,
                  Currency: currency,
                  Billing: billing,
                  Shipping: shipping,
                  Country: this.state.country.value,
                })
                .then((response) => {
                  if (response.status == 200) {
                    this.setState({
                      isSubmited: true,
                    });
                  }
                  console.log(response.status);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div>
        {this.state.isSubmited ? <Redirect to={`/Account`} /> : null}
        <Header as="h1" dividing>
          <span>New Account</span>
          <Checkbox
            onChange={() => {
              this.setState({ active: !this.state.active });
            }}
            checked={this.state.active}
            label="Active"
            style={{ float: 'right' }}
          />
        </Header>
        <br />
        <Grid columns="equal" stackable>
          <Grid.Row stretched>
            <Grid.Column width={3} verticalAlign="middle">
              <div>
                <h3
                  style={{
                    color: 'black',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  Name
                </h3>
              </div>
            </Grid.Column>
            <Grid.Column width={5} verticalAlign="middle">
              <Input
                placeholder="name"
                value={this.state.name}
                onChange={this.onNameInputChangeHandler}
                error={this.state.name == '' && this.state.isError}
              />
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <div>
                <h3
                  style={{
                    color: 'black',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  Country
                </h3>
              </div>
            </Grid.Column>
            <Grid.Column width={5} verticalAlign="middle">
              <Dropdown
                placeholder="country"
                search
                selection
                options={this.state.CountryData}
                value={this.state.country.value}
                onChange={this.onCountryDropDownChangeHandler}
                error={this.state.country == '' && this.state.isError}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={3} verticalAlign="middle">
              <div>
                <h3
                  style={{
                    color: 'black',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  Type
                </h3>
              </div>
            </Grid.Column>
            <Grid.Column width={5} verticalAlign="middle">
              <Dropdown
                placeholder="type"
                search
                selection
                options={this.state.TypeData}
                value={this.state.type.value}
                onChange={this.onTypeDropDownChangeHandler}
                error={this.state.type == '' && this.state.isError}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={16} verticalAlign="middle">
              <div>
                <h3
                  style={{
                    color: 'black',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  Contact
                </h3>
              </div>
              <br />
              <ContactListContainer AccountID={this.state.AccountID} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row stretched>
            <Grid.Column width={16} verticalAlign="middle">
              <div>
                <h3
                  style={{
                    color: 'black',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  Address
                </h3>
              </div>
              <br />
              <AddressListContainer AccountID={this.state.AccountID} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={10} verticalAlign="middle"></Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <Link to={'/Account'}>
                <Button
                  size="big"
                  className="CancelButton"
                  onClick={this.deleteNewAccount}
                >
                  Cancel
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              {/* <button type="submit">Submit</button> */}
              <button
                class="big ui button"
                type="submit"
                onClick={this.submitButtonOnClickHandler}
              >
                Submit
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default NewAccountContainer = connect(
  (state) => ({
    contactList: state.contactList,
    addressList: state.addressList,
    countryList: state.countryList,
    stateList: state.stateList,
  }),
  (dispatch) => ({
    getCountryList: bindActionCreators(getCountryList, dispatch),
    getStateList: bindActionCreators(getStateList, dispatch),
  })
)(NewAccountContainer);
