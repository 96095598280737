import withGetArrayData from '../../../HOCs/withGetArrayData';
import DataDropdown from './DataDropdown';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const ForwarderDropdown = withGetArrayData(
  DataDropdown,
  '/Forwarder',
  function (data) {
    return data.map((item) => {
      return {
        key: item.id,
        text: item.name,
        value: item.id,
      };
    });
  }
);

export default withReduxFormValidation(ForwarderDropdown);
