import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Header, Button } from 'semantic-ui-react';
import { List } from 'semantic-ui-react';

import withRequireLogin from '../../HOCs/withRequireLogin';
import SalesOrderListContainer from '../SalesOrder/containers/SalesOrderListContainer';

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Container>
          <SalesOrderListContainer />
        </Container>
      </div>
    );
  }
}

export default withRequireLogin(InvoiceList);
