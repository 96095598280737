import React, { Component } from 'react';
import {
  Grid,
  Container,
  Header,
  Button,
  Dropdown,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import Statistics_BarTable from '../Statistics/Statistics_BarTable';

class Management extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container>
        <Statistics_BarTable />
      </Container>
    );
  }
}

export default Management;
