import React, { Component, PropTypes } from 'react';
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Input,
  Icon,
} from 'semantic-ui-react';
import axios from '../../../util/CustomAxios';
import dateConverter from '../../../util/dateToYYYY-MM-DD';

class SalesOrderDateManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickUpDate: dateConverter(new Date()),
      shipmentDate: dateConverter(new Date()),
    };
    this.onPickUpDateChangeEventHandler =
      this.onPickUpDateChangeEventHandler.bind(this);
    this.onShipmentDateChangeEventHandler =
      this.onShipmentDateChangeEventHandler.bind(this);
  }
  onPickUpDateChangeEventHandler(event, data) {
    this.props.onDateChange({
      ...this.props.soDates,
      DateofPickUp: data.value,
    });
  }
  onShipmentDateChangeEventHandler(event, data) {
    this.props.onDateChange({
      ...this.props.soDates,
      DateofShipment: data.value,
    });
  }

  clearPickUpDate = () => {
    this.props.onDateChange({
      ...this.props.soDates,
      DateofPickUp: '',
    });
    this.setState();
  };

  clearShipmentDate = () => {
    this.props.onDateChange({
      ...this.props.soDates,
      DateofShipment: '',
    });
    this.setState();
  };

  render() {
    return (
      <Grid.Row stretched>
        <Grid.Column width={8} verticalAlign="middle">
          <Header>
            PickUp Date{' '}
            <Icon name="remove circle" onClick={this.clearPickUpDate} />
          </Header>
          <Input
            type="date"
            value={this.props.soDates.DateofPickUp}
            onChange={this.onPickUpDateChangeEventHandler}
          />
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle">
          <Header>
            Shipment Date{' '}
            <Icon name="remove circle" onClick={this.clearShipmentDate} />
          </Header>
          <Input
            type="date"
            value={this.props.soDates.DateofShipment}
            onChange={this.onShipmentDateChangeEventHandler}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default SalesOrderDateManager;
