import React, { Component } from 'react';

import InvoicePDFView from './InvoicePDFView';

class InvoicePDFContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <InvoicePDFView {...this.props} />;
  }
}

export default InvoicePDFContainer;
