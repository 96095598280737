import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAccountList, get } from '../../../action/account';
import AccountList from '../Presenter/AccountList';
import { getCountryList } from '../../../action/Country';
import { getStateList } from '../../../action/State';

import AccountAgGrid from '../Presenter/AccountAgGrid';

import axios from '../../../util/CustomAxios';

class AccountListContainer extends PureComponent {
  state = {
    accountTypeList: null,
  };
  async componentWillMount() {
    this.props.getAccountList();
    this.props.getCountryList();
    this.props.getStateList();

    const accountTypeList = await axios.get('/getAccountTypeList');
    await this.setState({
      accountTypeList: accountTypeList.data,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.accountTypeList &&
        this.props.countryList &&
        this.props.accountList ? (
          <AccountAgGrid
            accountList={this.props.accountList}
            countryList={this.props.countryList}
            accountTypeList={this.state.accountTypeList}
          />
        ) : null}
        <br />
        <br />
        <br />
        <AccountList {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    accountList: state.accountList,
    countryList: state.countryList,
  }),
  (dispatch) => ({
    getAccountList: bindActionCreators(getAccountList, dispatch),
    getCountryList: bindActionCreators(getCountryList, dispatch),
    getStateList: bindActionCreators(getStateList, dispatch),
  })
)(AccountListContainer);
