import React, { Component } from 'react';

export default class CheckboxRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleCheckboxChange(event) {
    this.props.data.checkbox = !this.props.data.checkbox;
    this.setState({ value: this.props.data.checkbox });
  }

  render() {
    return (
      <input
        type="checkbox"
        checked={this.state.value ? true : false}
        onChange={this.handleCheckboxChange}
      />
    );
  }
}
