import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  openCustomerBillingAddressSelector,
  closeCustomerBillingAddressSelector,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
} from '../../../action/customer';
import CustomerDetailWrapper from '../presenters/CustomerDetailWrapper';
import { patchAddressRequest } from '../../../action/account';
import { getCountryList } from '../../../action/Country';
import { getStateList } from '../../../action/State';

const CustomerDetailContainer = ({
  selectedCustomer,
  customerAddress,
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  isCustomerShppingAddressSelectorOpen,
  isCustomerBillingAddressSelectorOpen,
  openCustomerBillingAddressSelector,
  closeCustomerBillingAddressSelector,
  selectedBillingAddress,
  selectedShippingAddress,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
  patchAddressRequest,
  fields,
  countryList,
  stateList,
}) => {
  return (
    <div>
      <CustomerDetailWrapper
        selectedCustomer={selectedCustomer}
        customerAddress={customerAddress}
        openCustomerShppingAddressSelector={openCustomerShppingAddressSelector}
        closeCustomerShppingAddressSelector={
          closeCustomerShppingAddressSelector
        }
        isCustomerShppingAddressSelectorOpen={
          isCustomerShppingAddressSelectorOpen
        }
        openCustomerBillingAddressSelector={openCustomerBillingAddressSelector}
        closeCustomerBillingAddressSelector={
          closeCustomerBillingAddressSelector
        }
        isCustomerBillingAddressSelectorOpen={
          isCustomerBillingAddressSelectorOpen
        }
        selectedBillingAddress={selectedBillingAddress}
        selectedShippingAddress={selectedShippingAddress}
        setSelectedBillingAddress={setSelectedBillingAddress}
        setSelectedShippingAddress={setSelectedShippingAddress}
        patchAddressRequest={patchAddressRequest}
        fields={fields}
        stateList={stateList}
        countryList={countryList}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    selectedCustomer: state.selectedCustomer.billingCustomer,
    customerAddress: state.customerAddress,
    isCustomerShppingAddressSelectorOpen:
      state.isCustomerShppingAddressSelectorOpen,
    isCustomerBillingAddressSelectorOpen:
      state.isCustomerBillingAddressSelectorOpen,
    selectedBillingAddress: state.selectedAddress.billingAddress,
    selectedShippingAddress: state.selectedAddress.shippingAddress,
  }),
  (dispatch) => ({
    openCustomerShppingAddressSelector: bindActionCreators(
      openCustomerShppingAddressSelector,
      dispatch
    ),
    closeCustomerShppingAddressSelector: bindActionCreators(
      closeCustomerShppingAddressSelector,
      dispatch
    ),
    openCustomerBillingAddressSelector: bindActionCreators(
      openCustomerBillingAddressSelector,
      dispatch
    ),
    closeCustomerBillingAddressSelector: bindActionCreators(
      closeCustomerBillingAddressSelector,
      dispatch
    ),
    setSelectedBillingAddress: bindActionCreators(
      setSelectedBillingAddress,
      dispatch
    ),
    setSelectedShippingAddress: bindActionCreators(
      setSelectedShippingAddress,
      dispatch
    ),
    patchAddressRequest: bindActionCreators(patchAddressRequest, dispatch),
    getCountryList: bindActionCreators(getCountryList, dispatch),
    getStateList: bindActionCreators(getStateList, dispatch),
  })
)(CustomerDetailContainer);
