import React, { Component } from 'react';

import SalesOrderDFView from './SalesOrderPDFView';

class SalesOrderPDFContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <SalesOrderDFView {...this.props} />;
  }
}

export default SalesOrderPDFContainer;
