import React, { Component } from 'react';
import withGetArrayData from '../../../HOCs/withGetArrayData';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import ClickableSpan from '../../General/ClickableSpan';
import Select from 'react-select';

class UserDropdown extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const options = this.props.data.map((item) => (
      <option value={item.first_name} key={item.key}>
        {item.text}
      </option>
    ));
    return (
      <select
        onChange={this.props.onChange}
        style={{ width: '100%' }}
        value={this.props.value}
      >
        <option value="">All User</option>
        {options}
      </select>
    );
  }
}

export default withGetArrayData(UserDropdown, '/User', (array) =>
  array.map((item) => {
    return {
      value: item.first_name + ' ' + item.last_name,
      key: item.id,
      text: item.first_name + ' ' + item.last_name,
    };
  })
);
