import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import CheckboxRenderer from './CheckBoxRenderer';

import Axios from '../../../util/CustomAxios';

class AccountAgGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: 'ID',
          field: 'AccountID',
          filter: 'agNumberColumnFilter',
          editable: false,
          hide: true,
        },
        {
          headerName: 'Name',
          field: 'Name',
          filter: 'agTextColumnFilter',
          editable: true,
        },
        {
          headerName: 'Country',
          field: 'country',
          filter: 'agSetColumnFilter',
          cellEditor: 'agPopupSelectCellEditor',
          enableRowGroup: true,
          editable: true,
          width: 150,
          cellEditorParams: (params) => {
            const countryIdList = this.props.countryList.map(
              (type) => type.CountryID
            );

            return {
              values: countryIdList,
            };
          },
          valueParser: (params) => {
            // convert value to code
            console.log('test');
            const countryList = this.props.countryList;
            const matchedObj = this.lookupValue(
              countryList,
              'Name',
              params.newValue
            );
            return matchedObj ? matchedObj.CountryID : null;
          },
          valueFormatter: (params) => {
            // convert code to value
            const matchedObj = this.lookupValue(
              this.props.countryList,
              'CountryID',
              Number(params.value)
            );
            if (matchedObj) {
              return matchedObj.Name;
            }
            return null;
          },
        },
        {
          headerName: 'Type',
          field: 'TypeID',
          filter: 'agSetColumnFilter',
          cellEditor: 'agPopupSelectCellEditor',
          enableRowGroup: true,
          editable: true,
          width: 100,
          cellEditorParams: (params) => {
            const typeIdList = this.props.accountTypeList.map(
              (type) => type.TypeID
            );

            return {
              values: typeIdList,
            };
          },
          valueParser: (params) => {
            // convert value to code
            const matchedObj = this.lookupValue(
              this.props.accountTypeList,
              'Type',
              params.newValue
            );
            return matchedObj ? matchedObj.TypeID : null;
          },
          valueFormatter: (params) => {
            // convert code to value
            const matchedObj = this.lookupValue(
              this.props.accountTypeList,
              'TypeID',
              Number(params.value)
            );
            if (matchedObj) {
              return matchedObj.Type;
            }
            return null;
          },
        },
        {
          headerName: 'Status',
          field: 'Status',
          editable: true,
          width: 80,
          cellEditor: 'popupSelect',
          cellEditorParams: {
            values: [true, false],
          },
        },
        {
          headerName: 'State',
          field: 'state',
          editable: true,
          width: 150,
        },
        {
          headerName: 'City',
          field: 'city',
          editable: true,
          width: 150,
        },
        {
          headerName: 'Address',
          field: 'address',
          editable: true,
        },
        {
          headerName: 'Postal Code',
          field: 'postal_code',
          editable: true,
          width: 100,
        },
        {
          headerName: 'Ship = Bill',
          field: 'is_same_address',
          editable: false,
          width: 100,
        },
      ],
      rowData: this.props.accountList,
      defaultColDef: { editable: true },
      frameworkComponents: {
        CheckboxRenderer: CheckboxRenderer,
      },
    };
  }

  lookupValue = (list, key, value) => {
    for (let item of list) {
      if (item[key] === value) {
        return item;
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      rowData: nextProps.accountList,
    });
  }

  onButtonClick = (e) => {
    this.saveFilterModel();
    console.log(localStorage.filter);
  };

  saveFilterModel = () => {
    localStorage.filter = JSON.stringify(this.gridApi.getFilterModel());
  };

  onGridLoad = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.restoreFilterModel()
  };

  autoSizeAll = () => {
    const allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  };

  restoreFilterModel = () => {
    const storedFilter = JSON.parse(localStorage.filter);
    console.log(storedFilter);
    this.gridApi.setFilterModel(storedFilter);
    this.gridApi.onFilterChanged();
  };

  onCellValueChanged = async (params) => {
    const colId = params.column.getId();
    if (params.value == 'true') {
      params.value = 1;
    } else if (params.value == 'false') {
      params.value = 0;
    }
    const newValue = {
      [colId]: params.value,
    };
    console.log(params);

    if (
      colId == 'state' ||
      colId == 'city' ||
      colId == 'address' ||
      colId == 'postal_code'
    ) {
      const res = await this.changeAddressValue(
        params.data.address_id,
        newValue
      );
    } else {
      const res = await this.changeAccountValue(
        params.data.AccountID,
        newValue
      );
    }
  };

  changeAccountValue = async (id, newValue) => {
    const res = await Axios.patch('/Account/' + id, newValue);
    return res;
  };

  changeAddressValue = async (id, newValue) => {
    const res = await Axios.patch('/Address/' + id, newValue);
    return res;
  };

  render() {
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: '500px',
          width: '100%',
        }}
      >
        <AgGridReact
          enableSorting={true}
          enableFilter={true}
          animateRows={true}
          enableRangeSelection={true}
          onGridReady={this.onGridLoad}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          rowData={this.state.rowData}
          onCellValueChanged={this.onCellValueChanged}
          frameworkComponents={this.state.frameworkComponents}
          groupSelectsChildren={true}
          showToolPanel={true}
          enableColResize={true}
        ></AgGridReact>
        <button onClick={this.onButtonClick}>Save Filter</button>
        <button onClick={this.autoSizeAll}>Auto Size</button>
      </div>
    );
  }
}

export default AccountAgGrid;
