import React, { Component } from 'react';
import withGetArrayData from '../../../HOCs/withGetArrayData';

class SalesOrderPaidStatusDropdown extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const options = this.props.data.map((item) => (
      <option value={item.value} key={item.value}>
        {item.text}
      </option>
    ));
    return (
      <select
        onChange={this.props.onChange}
        style={{ width: '100%' }}
        value={this.props.value}
      >
        <option value="">All Types</option>
        {options}
      </select>
    );
  }
}

export default withGetArrayData(
  SalesOrderPaidStatusDropdown,
  '/SalesOrderPaidStatus',
  (array) =>
    array.map((item) => {
      return {
        value: item.name.trim(),
        key: item.id,
        text: item.name.trim(),
      };
    })
);
