import React, { Component } from 'react';
import {
  Label,
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Radio,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import axios from '../../util/CustomAxios';
import { Link, withRouter } from 'react-router-dom';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import NumberFormat from 'react-number-format';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIsSODatesUpdateComplete } from '../../action/salesOrder';

import SalesOrderProductList from './presenters/SalesOrderProductList';

import './SalesOrder.css';
import SalesOrderPDFView from './presenters/SalesOrderPDFContainer';
import SalesOrderPDFViewAA from './presenters/SalesOrderPDFViewAA';

import ConfirmModal from '../General/ConfirmModal';
import SalesOrderMutableStatusManager from './presenters/SalesOrderMutableStatusManager';
import ShippingForm from './containers/ShippingForm';
import { Form } from 'semantic-ui-react';

import ReactToPrint from 'react-to-print';
import CustomerContact from './presenters/CustomerContact';
import CustomerAddress from './presenters/CustomerAddress';
import TempInvoicePDFView from './presenters/TempInvoicePDFView';

const propTypes = {};

const defaultProps = {};

class SalesOrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SOID: null,
      detail: {
        SONumber: 'SO-0000',
        SOIssueDate: new Date().toISOString().substring(0, 10),
        SalesOrderType: '',
        TermsOfDelivery: '',
        DeliveryMethod: '',
      },
      isDetailLoading: true,
      items: [],
      isPDFView: false,
      soDates: {
        DateofPickUp: null,
        DateofShipment: null,
      },
      isFTA: true,
      isShowShippingForm: false,
      isShowDiscount: true,
      invoiceList: [],
      canHaveInvoice: false,
      isConfirmed: false,
      dropShipContact: null,
    };
    this.onPDFToggleClickHandler = this.onPDFToggleClickHandler.bind(this);
    this.printDocument = this.printDocument.bind(this);
  }

  printDocument(isFTA) {
    this.setState(
      {
        isFTA: isFTA,
      },
      () => {
        const input = document.getElementById('SalesOrderPDF');
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
          // pdf.output('dataurlnewwindow');
          pdf.save(`${this.state.detail.SONumber.trim()}.pdf`);
        });
      }
    );
  }

  pdfToHTML() {
    var pdf = new jsPDF('p', 'pt', 'letter');
    var source = document.getElementById('SalesOrderPDF');
    var specialElementHandlers = {
      '#bypassme': function (element, renderer) {
        return true;
      },
    };

    var margins = {
      top: 50,
      left: 60,
      width: 545,
    };

    pdf.fromHTML(
      source, // HTML string or DOM elem ref.
      margins.left, // x coord
      margins.top, // y coord
      {
        width: margins.width, // max width of content on PDF
        elementHandlers: specialElementHandlers,
      },
      function (dispose) {
        // dispose: object with X, Y of the last line add to the PDF
        // this allow the insertion of new lines after html
        pdf.save('html2pdf.pdf');
      }
    );
  }

  async componentDidMount() {
    let { SOID } = this.props;

    if (!SOID) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }

    this.setState({ SOID });

    try {
      await Promise.all([
        this.fetchSalesOrderDetails(SOID),
        this.fetchSalesOrderItems(SOID),
        this.fetchSalesOrderDates(SOID),
        this.fetchInvoiceList(SOID),
        this.checkIfCanHaveInvoice(SOID),
      ]);

      await this.getDropShipContact();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isDetailLoading: false });
    }
  }

  fetchSalesOrderDetails = async (SOID) => {
    try {
      const response = await axios.get(`/SalesOrder/view/${SOID}`);
      const detail = response.data[0];
      detail.SOIssueDate = new Date(detail.SOIssueDate)
        .toISOString()
        .substring(0, 10);
      detail.Currency = detail.Currency.trim();

      this.setState({
        detail,
        isConfirmed: detail.airtable_id,
      });

      if (detail) {
        document.title = `${detail.SONumber}_${detail.ShippingAccountName}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchSalesOrderItems = async (SOID) => {
    try {
      const response = await axios.get(`/SalesOrder/${SOID}/Items`);
      this.setState({ items: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  fetchSalesOrderDates = async (SOID) => {
    try {
      const response = await axios.get(`/SalesOrder/${SOID}/Dates`);
      const soDates = response.data[0];

      this.setState({
        soDates: {
          DateofPickUp: soDates.DateofPickUp
            ? new Date(soDates.DateofPickUp).toISOString().substring(0, 10)
            : null,
          DateofShipment: soDates.DateofShipment
            ? new Date(soDates.DateofShipment).toISOString().substring(0, 10)
            : null,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchInvoiceList = async (SOID) => {
    try {
      const response = await axios.get(`/SalesOrder/Invoice/${SOID}`);
      this.setState({ invoiceList: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  checkIfCanHaveInvoice = async (SOID) => {
    try {
      const response = await axios.get(`/SalesOrder/can-have-invoice/${SOID}`);
      this.setState({ canHaveInvoice: response.data.canHaveInvoice });
    } catch (error) {
      console.error(error);
    }
  };

  getSODates() {
    const SOID = this.props.SOID
      ? this.props.SOID
      : this.props.match.params.SOID;
    axios
      .get(`/SalesOrder/${SOID}/Dates`)
      .then((response) => {
        console.log(response.data[0]);
        this.setState({
          soDates: {
            DateofPickUp:
              response.data[0].DateofPickUp == null
                ? null
                : new Date(response.data[0].DateofPickUp)
                    .toISOString()
                    .substring(0, 10),
            DateofShipment:
              response.data[0].DateofShipment == null
                ? null
                : new Date(response.data[0].DateofShipment)
                    .toISOString()
                    .substring(0, 10),
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getSOPaid() {
    const SOID = this.props.SOID
      ? this.props.SOID
      : this.props.match.params.SOID;
    axios
      .get(`/SalesOrder/${SOID}/Paid`)
      .then((response) => {
        this.setState({
          detail: {
            ...this.state.detail,
            paid: response.data[0].paid,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getDropShipContact() {
    const { detail } = this.state;
    const accountId = detail.AccountID;
    const dropShipContactId = detail.dropship_contact_id;
    try {
      if (!accountId) return;
      const response = await axios.get(`/Contact/${accountId}`);
      const contacts = response.data;
      const dropShipContact = contacts.find(
        (contact) => contact.id === dropShipContactId
      );

      if (dropShipContact) {
        this.setState({ dropShipContact });
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isSODatesUpdateComplete &&
      !prevProps.isSODatesUpdateComplete
    ) {
      this.getSODates();
      this.getSOPaid();
      this.props.setIsSODatesUpdateComplete(false);
      this.getDropShipContact();
    }

    if (
      prevState.detail.dropship_contact_id !==
      this.state.detail.dropship_contact_id
    ) {
      this.getDropShipContact();
    }
  }

  onPDFToggleClickHandler() {
    this.setState({
      isPDFView: !this.state.isPDFView,
    });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onInvoiceClick = async () => {
    const hasInvoice = this.state.detail.inv_num;
    const SOID = this.props.SOID
      ? this.props.SOID
      : this.props.match.params.SOID;

    this.props.history.push(`/SalesOrder/new-invoice/${SOID}`);
  };

  onFTAToggle = (e) => {
    this.setState({
      isFTA: !this.state.isFTA,
    });
  };

  onShowDiscountToggle = (e) => {
    this.setState({
      isShowDiscount: !this.state.isShowDiscount,
    });
  };

  onConfirmButtonClick = async (e) => {
    let SOID = this.props.SOID;
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }

    this.setState({
      isConfirming: true,
    });
    try {
      const res = await axios.post(`/SalesOrder/confirm/${SOID}`);
      if (res.status == 200) {
        this.setState({
          isConfirmed: true,
        });
        alert('DONE !');
      }
    } catch (err) {
      console.error(err);
      alert(
        err.response.data.message ||
          '요청에 실패했습니다. 관리자에게 문의해주세요.'
      );
    } finally {
      this.setState({
        isConfirming: false,
      });
    }
  };

  onApplyDesButtonClick = async (e) => {
    let SOID = this.props.SOID;
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }

    try {
      const res = await axios.post(
        `/SalesOrder/description/${SOID}`,
        this.state.items
      );
      if (res.status == 200) {
        alert('DONE !');
      }
    } catch (err) {
      console.error(err);
      alert(
        err.response.data.message ||
          '요청에 실패했습니다. 관리자에게 문의해주세요.'
      );
    }
  };

  onClickClone = async (e) => {
    let SOID = this.props.SOID;
    if (this.props.SOID == null) {
      const {
        match: { params },
      } = this.props;
      SOID = params.SOID;
    }
    const res = await axios.post(`/SalesOrder/duplicate/${SOID}`);
    this.props.history.push(`/SalesOrder/`);
    this.props.history.push(`/SalesOrder/${res.data.SOID}`);
  };

  changeNote = async () => {
    this.setState({
      isInvoiceLoading: true,
    });
    const result = await axios.patch(`/SalesOrder/${this.state.SOID}`, {
      note: this.state.detail.note,
    });
    this.setState({
      detail: {
        SONumber: 'SO-0000',
        SOIssueDate: new Date().toISOString().substring(0, 10),
        SalesOrderType: '',
        TermsOfDelivery: '',
        DeliveryMethod: '',
      },
    });
    this.componentWillMount();
  };

  onNoteChange = (e) => {
    this.setState({
      detail: {
        ...this.state.detail,
        note: e.target.value,
      },
    });
  };

  onCopyLinkMarkdown = async (e) => {
    const markdown = `[${this.state.detail.SONumber}](${window.location}/${this.state.SOID})`;
    await navigator.clipboard.writeText(markdown);
  };

  onRedirectClickUpTask = async (e) => {
    axios
      .get(`/SalesOrder/clickUp/task/${this.state.detail.SONumber}`)
      .then((response) => {
        if (response.data.length === 0) {
          alert('No task found');
          return;
        }
        const task =
          response.data.length === 1
            ? response.data[0]
            : response.data.sort((a, b) => a.id - b.id)[0];

        window.open(`https://app.clickup.com/t/${task.id}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { dropShipContact } = this.state;
    return (
      <div>
        <Container>
          <Dimmer active={this.state.isDetailLoading}>
            <Loader>Loading...</Loader>
          </Dimmer>
          <Dimmer active={this.state.isConfirming}>
            <Loader>Confirming...</Loader>
          </Dimmer>
          <Button onClick={this.onCopyLinkMarkdown}>Copy 🔗</Button>
          <Button onClick={this.onRedirectClickUpTask}>ClickUp Link</Button>
          <Header as="h2" dividing>
            <Header.Content>{this.state.detail.SONumber.trim()}</Header.Content>
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              Switch PDF{' '}
            </span>
            <Radio
              onClick={this.onPDFToggleClickHandler}
              floated="right"
              toggle
              checked={this.state.isPDFView}
            />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              FTA{' '}
            </span>
            <Radio
              onClick={this.onFTAToggle}
              floated="right"
              toggle
              checked={this.state.isFTA}
            />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '12pt',
                fontWeight: '200',
              }}
            >
              Show Discount{' '}
            </span>
            <Radio
              onClick={this.onShowDiscountToggle}
              floated="right"
              toggle
              checked={this.state.isShowDiscount}
            />
            {this.state.isConfirmed ? null : (
              <Link to={`/SalesOrder/edit/${this.state.SOID}`}>
                <Button floated="right">Edit</Button>
              </Link>
            )}
            <ConfirmModal
              Target={this.state.detail.SONumber.trim()}
              Content="Void"
              onConfirmButtonClickEvent={() => {
                axios
                  .delete(`/SalesOrder/${this.state.SOID}`)
                  .then((response) => {
                    window.location.href = '/SalesOrder';
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }}
            />
            {this.state.canHaveInvoice && this.state.isConfirmed ? (
              <Button
                floated="right"
                loading={this.state.isInvoiceLoading}
                onClick={this.onInvoiceClick}
              >
                Create Invoice
              </Button>
            ) : null}

            <SalesOrderMutableStatusManager
              SOID={this.state.SOID}
              soDates={this.state.soDates}
              paid_id={this.state.detail.paid_id}
              setIsSODatesUpdateComplete={this.props.setIsSODatesUpdateComplete}
            />
          </Header>
          <Header as="h2">
            <Header.Content>
              Sales Order
              {this.state.detail.test ? (
                <Label
                  style={{
                    background: '#F05252',
                    color: 'white',
                  }}
                >
                  test
                </Label>
              ) : null}
              <Header.Subheader>#{this.state.detail.SONumber}</Header.Subheader>
            </Header.Content>
            <Button.Group floated="right">
              {this.state.isConfirmed ? null : (
                <Button onClick={this.onConfirmButtonClick}>Confirm</Button>
              )}
              <Button onClick={this.onClickClone}>Clone</Button>
              <ReactToPrint
                trigger={() => <Button>PDF</Button>}
                content={() => this.pdfRef}
                pageStyle={`@page { size: auto;  margin: 20mm; }`}
              />
              <ReactToPrint
                trigger={() => <Button>Temp Invoice PDF</Button>}
                documentTitle={`Invoice_${document.title}`}
                content={() => this.pdfRefTempPDF}
                pageStyle={`@page { size: auto;  margin: 20mm; }`}
              />
            </Button.Group>
          </Header>
          <Grid
            columns="equal"
            stackable
            style={this.state.isPDFView ? { display: 'none' } : {}}
            detail={this.state.detail}
          >
            <Grid.Row stretched />
            {this.state.detail == null ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.SOIssueDate ? (
                    <div>
                      <h5 className="ItemDetail__Header">Zoho SO Number</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.zoho_so_number}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.SalesOrderType ? (
                    <div>
                      <h5 className="ItemDetail__Header">Reference Number</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.reference_number}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.detail == null ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.SOIssueDate ? (
                    <div>
                      <h5 className="ItemDetail__Header">Sales Order Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.SOIssueDate}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.SalesOrderType ? (
                    <div>
                      <h5 className="ItemDetail__Header">Sales Order Type</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.SalesOrderType}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.soDates.DateofPickUp != null ||
            this.state.soDates.DateofShipment != null ? (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.soDates.DateofPickUp != null ? (
                    <div>
                      <h5 className="ItemDetail__Header">Pick Up Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.soDates.DateofPickUp}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.soDates.DateofShipment != null ? (
                    <div>
                      <h5 className="ItemDetail__Header">Shipment Date</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.soDates.DateofShipment}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {this.state.detail == null ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.TermsOfDelivery ? (
                    <div>
                      <h5 className="ItemDetail__Header">Terms Of Delivery</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.TermsOfDelivery}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  {this.state.detail.DeliveryMethod ? (
                    <div>
                      <h5 className="ItemDetail__Header">Delivery Method</h5>
                      <p className="ItemDetail__Contents">
                        {this.state.detail.DeliveryMethod}
                      </p>
                    </div>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row>
              <Grid.Column width={8} verticalAlign="top">
                <CustomerAddress
                  header="Billing Address"
                  accountName={
                    this.state.detail.BillingAccountName ||
                    this.state.detail.BillingCustomerName
                  }
                  address={this.state.detail.BillingAddress}
                  state={this.state.detail.BillingState}
                  city={this.state.detail.BillingCity}
                  postal_code={this.state.detail.BillingPostalCode}
                  countryName={this.state.detail.BillingCountry}
                  attention_input={this.state.detail.BillingAttentionInput}
                  isEditable={false}
                />
                <CustomerContact
                  header="Billing Contact"
                  name={this.state.detail.BillingContactName}
                  email={this.state.detail.BillingContactEmail}
                  phone={this.state.detail.BillingContactPhone}
                  mobile_phone={this.state.detail.BillingContactMobilePhone}
                />
              </Grid.Column>
              <Grid.Column width={8} verticalAlign="top">
                <CustomerAddress
                  header="Shipping Address"
                  accountName={
                    this.state.detail.ShippingAccountName ||
                    this.state.detail.ShippingCustomerName
                  }
                  address={this.state.detail.ShippingAddress}
                  state={this.state.detail.ShippingState}
                  city={this.state.detail.ShippingCity}
                  postal_code={this.state.detail.ShippingPostalCode}
                  countryName={this.state.detail.ShippingCountry}
                  attention_input={this.state.detail.ShippingAttentionInput}
                  isEditable={false}
                />
                {/* DropShip Contact가 존재하면 따로 표시 */}
                {dropShipContact ? (
                  <CustomerContact
                    header="DropShip Contact"
                    name={dropShipContact.name}
                    email={dropShipContact.email}
                    phone={dropShipContact.phone}
                    mobile_phone={dropShipContact.mobile_phone}
                  />
                ) : (
                  <CustomerContact
                    header="Shipping Contact"
                    name={this.state.detail.ShippingContactName}
                    email={this.state.detail.ShippingContactEmail}
                    phone={this.state.detail.ShippingContactPhone}
                    mobile_phone={this.state.detail.ShippingContactMobilePhone}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign="middle">
                {this.state.detail.DeliveryMethod ? (
                  <div>
                    <h5 className="ItemDetail__Header">Paid Status</h5>
                    <p className="ItemDetail__Contents">
                      {this.state.detail.paid || 'Unpaid'}
                    </p>
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="top">
                {this.state.items == null ? null : (
                  <div>
                    <SalesOrderProductList
                      items={this.state.items.map((item, index) => ({
                        ...item,
                        onCustomDescription: (e) => {
                          const tmpArray = [...this.state.items];
                          tmpArray[index].customDescription = e.target.value;
                          this.setState({
                            items: tmpArray,
                          });
                        },
                      }))}
                    />
                  </div>
                )}
                <Button onClick={this.onApplyDesButtonClick}>
                  Apply Description
                </Button>
              </Grid.Column>
            </Grid.Row>

            {this.state.detail.Adjustment == null ||
            this.state.detail.Adjustment == 0 ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h5 className="ItemDetail__Header">Adjustment</h5>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h4
                    style={{
                      textAlign: 'right',
                      marginRight: '14px',
                    }}
                  >
                    <NumberFormat
                      value={this.state.detail.Adjustment}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                    />
                  </h4>
                </Grid.Column>
              </Grid.Row>
            )}

            {this.state.detail.ShippingCharge == null ||
            this.state.detail.ShippingCharge == 0 ? null : (
              <Grid.Row stretched>
                <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h5 className="ItemDetail__Header">Shipping Charge</h5>
                </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  <h4
                    style={{
                      textAlign: 'right',
                      marginRight: '14px',
                    }}
                  >
                    <NumberFormat
                      value={this.state.detail.ShippingCharge}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                    />
                  </h4>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row stretched>
              <Grid.Column width={8} verticalAlign="middle"></Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <h5 className="ItemDetail__Header">Total</h5>
              </Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <h4
                  style={{
                    textAlign: 'right',
                    marginRight: '14px',
                  }}
                >
                  <NumberFormat
                    value={this.state.detail.Amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={this.state.detail.Currency == 'KRW' ? '₩' : '$'}
                  />
                </h4>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="middle">
                <Form>
                  <Form.TextArea
                    style={{ height: '150px' }}
                    label="Note"
                    onChange={this.onNoteChange}
                    value={this.state.detail.note}
                  />
                  <Form.Button onClick={this.changeNote}>Change</Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>

            {this.state.detail.packing_info ? (
              <Grid.Row stretched>
                <Grid.Column width={16} verticalAlign="middle">
                  <h4>Packing Info</h4>
                  <div className="SalesOrderPDF__BackAccountContainer">
                    {this.state.detail.packing_info.split('\n').map((text) => (
                      <p>{text}</p>
                    ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            ) : null}

            <Grid.Row stretched>
              <Grid.Column width={16} verticalAlign="middle">
                <ul>
                  {Array.isArray(this.state.invoiceList) &&
                    this.state.invoiceList.map((invoice) => (
                      <p>
                        <Link to={`/Invoice/${invoice.id}`}>
                          {invoice.inv_num}
                        </Link>
                      </p>
                    ))}
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <div>
          <Modal
            open={this.state.isShowShippingForm}
            onClose={this.handleCloseModal}
            closeIcon={10}
          >
            <br />
            <ShippingForm soid={this.state.SOID} />
            <br />
          </Modal>
        </div>
        <div ref={(ref) => (this.pdfRef = ref)}>
          {!!this.state.detail &&
          this.state.detail.company === 'Aurender America Inc.' ? (
            <SalesOrderPDFViewAA
              isShowDiscount={this.state.isShowDiscount}
              isFTA={this.state.isFTA}
              style={this.state.isPDFView ? {} : { zIndex: '-1' }}
              detail={this.state.detail}
              items={this.state.items}
            />
          ) : (
            <SalesOrderPDFView
              isShowDiscount={this.state.isShowDiscount}
              isFTA={this.state.isFTA}
              style={this.state.isPDFView ? {} : { zIndex: '-1' }}
              detail={this.state.detail}
              items={this.state.items}
            />
          )}
        </div>
        <div ref={(ref) => (this.pdfRefTempPDF = ref)}>
          <TempInvoicePDFView
            isShowDiscount={this.state.isShowDiscount}
            isFTA={this.state.isFTA}
            style={{ zIndex: '-1' }}
            detail={this.state.detail}
            items={this.state.items}
          />
        </div>
      </div>
    );
  }
}

SalesOrderItem.PropTypes = propTypes;
SalesOrderItem.defaultProps = defaultProps;

export default SalesOrderItem = withRouter(
  connect(
    (state) => ({
      isSODatesUpdateComplete: state.isSODatesUpdateComplete,
    }),
    (dispatch) => ({
      setIsSODatesUpdateComplete: bindActionCreators(
        setIsSODatesUpdateComplete,
        dispatch
      ),
    })
  )(SalesOrderItem)
);
