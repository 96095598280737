import React, { Component, PropTypes } from 'react';
import axios from 'axios';
import { Unauthorized } from '../component/General/Unauthorized';

const withRequireLogin = (Component, url, mapper) => {
  return class extends Component {
    render() {
      if (localStorage.getItem('APIToken')) {
        return <Component data={this.state.data} {...this.props} />;
      } else {
        return <Unauthorized />;
      }
    }
  };
};

export default withRequireLogin;
