import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Header, Button } from 'semantic-ui-react';
import { List } from 'semantic-ui-react';

import SalesOrderListContainer from './containers/SalesOrderListContainer';
import withRequireLogin from '../../HOCs/withRequireLogin';

const propTypes = {};

const defaultProps = {};

class SalesOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        style={{
          margin: '0 20px 0 80px',
        }}
      >
        <Container fluid>
          {/*<SalesOrderListFilter/>*/}
          <SalesOrderListContainer />
        </Container>
      </div>
    );
  }
}

SalesOrderList.PropTypes = propTypes;
SalesOrderList.defaultProps = defaultProps;

export default withRequireLogin(SalesOrderList);
