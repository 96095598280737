import React, { Component } from 'react';

import {
  Grid,
  Container,
  Header,
  Button,
  Dropdown,
  Checkbox,
  Label,
  Input,
} from 'semantic-ui-react';
import axios from '../../util/CustomAxios';
import config from '../../config';

class ExperimentalPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SOLink: '',
      createdLink: '',
    };
  }

  postSO = async (id) => {
    const result = await axios.post(`/Experimental/CloneSO/${id}`);
    return result;
  };

  onCloneClick = async () => {
    let SOid;

    let re1 = '.*?'; // Non-greedy match on filler
    var re2 = '(\\d+)'; // Integer Number 1

    let p = new RegExp(re1 + re2, ['i']);
    let m = p.exec(this.state.SOLink);
    if (m != null) {
      let int1 = m[1];
      SOid = int1.replace(/</, '&lt;');
    } else {
      this.setState({
        SOLink: '주소형식이 잘못되었습니다.',
      });
      return;
    }

    const SOInfo = await this.postSO(SOid);
    this.setState({
      createdLink:
        'https://inventory.zoho.com/app#/salesorders/' +
        SOInfo.data.salesorder.salesorder_id,
    });
    return;
  };

  onSOLinkChange = (e) => {
    this.setState({
      SOLink: e.target.value,
    });
  };

  render() {
    return (
      <Container>
        <Header as="h1" dividing>
          <span>Clone SO</span>
        </Header>
        <Container fluid textAlign="center">
          <div>
            <h3>AA => AI, 복사할 SO의 주소를 입력해 주세요.</h3>
            <Input
              value={this.state.SOLink}
              onChange={this.onSOLinkChange}
              style={{ width: '300px', marginRight: '10px' }}
            />
            <Button onClick={this.onCloneClick}>복사</Button>
            <br />
            <a href={this.state.createdLink}>{this.state.createdLink}</a>
          </div>
        </Container>
      </Container>
    );
  }
}

export default ExperimentalPage;
