import ReactTable from 'react-table';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Container, Header } from 'semantic-ui-react';

import NewAccountContainer from './Container/NewAccountContainer';

class NewAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      AccountID: null,
    };
  }

  render() {
    return (
      <Container>
        <NewAccountContainer />
      </Container>
    );
  }
}

export default NewAccount;
