import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerList } from '../../../action/customer';
import {
  getSalesOrderList,
  setTestFilterEnable,
  setDateFilterEnable,
  setShipmentDateFilterEnable,
} from '../../../action/salesOrder';
import NumberFormat from 'react-number-format';
import SalesOrderListItem from '../presenters/SalesOrderListItem';
import { Header, Button, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { BallLoader } from '../../General/BallLoader';
import axios from '../../../util/CustomAxios';

const propTypes = {};

const defaultProps = {};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class SalesOrderListContainer extends PureComponent {
  componentWillMount() {
    const Company = 'Aurender Inc.';
    this.props.getCustomerList();
    this.props.getSalesOrderList(this.props.company);
    this.setState({
      isFirst: true,
      isLoading: false,
      updateFromAirtableStatus: 'Update from Airtable',
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.salesOrderList != nextProps.salesOrderList) {
      this.setState({
        isLoading: true,
        isFirst: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
    if (this.props.company !== nextProps.company) {
      nextProps.getSalesOrderList(nextProps.company);
    }
    console.log(nextProps.salesOrderList);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isLoading) {
      this.setState({
        isLoading: false,
      });
      return false;
    }
    return true;
  }

  handleTestFilter = (event, target) => {
    this.props.setTestFilterEnable(target.checked);
    console.log(target.checked);
  };

  updateFromAirtable = () => {
    this.setState({
      updateFromAirtableStatus: 'Updating...',
    });
    axios
      .post('/SalesOrder/UpdateFromAirtable')
      .then((res) => {
        this.setState({
          updateFromAirtableStatus: 'Updated',
        });
        window.location.reload();
        setTimeout(() => {
          this.setState({
            updateFromAirtableStatus: 'Update from Airtable',
          });
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          updateFromAirtableStatus: 'Error',
        });
        setTimeout(() => {
          this.setState({
            updateFromAirtableStatus: 'Update from Airtable',
          });
        }, 2000);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading || this.state.isFirst ? <BallLoader /> : null}
        <Header as="h1" dividing>
          Sales Order
          <Button
            onClick={this.updateFromAirtable}
            floated="Left"
            style={{ marginLeft: '10px' }}
          >
            {this.state.updateFromAirtableStatus}
          </Button>
          <Link to="/NewSalesOrder">
            <Button floated="right">New</Button>
          </Link>
          <Checkbox
            style={{
              float: 'right',
              marginTop: '10px',
              marginRight: '10px',
            }}
            label={<label>test</label>}
            onClick={this.handleTestFilter}
          />
        </Header>
        <SalesOrderListItem
          data={this.props.salesOrderList}
          customerList={this.props.customerList}
          setDateFilterEnable={this.props.setDateFilterEnable}
          setShipmentDateFilterEnable={this.props.setShipmentDateFilterEnable}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    isDatePickerPicked: state.isDatePickerPicked,
    isShipmentDatePicked: state.isShipmentDatePicked,
    salesOrderList: state.salesOrderList
      .map((salesOrder) => ({
        ...salesOrder,
        SONumber: salesOrder.SONumber.trim(),
        SOIssueDate: new Date(salesOrder.SOIssueDate)
          .toISOString()
          .substring(0, 10),
        DateofShipment:
          salesOrder.DateofShipment == null
            ? ''
            : new Date(salesOrder.DateofShipment)
                .toISOString()
                .substring(0, 10),
        DateofPickUp:
          salesOrder.DateofPickUp == null
            ? ''
            : new Date(salesOrder.DateofPickUp).toISOString().substring(0, 10),
        Amount: (
          <span style={{ textAlign: 'right', display: 'block' }}>
            <NumberFormat
              value={salesOrder.Amount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={
                salesOrder.Currency == null
                  ? '＄'
                  : salesOrder.Currency.trim() == 'USD'
                    ? '＄'
                    : salesOrder.Currency.trim() == 'KRW'
                      ? '￦'
                      : '＄'
              }
            />
          </span>
        ),
      }))
      .filter((salesOrder) => {
        return state.isTestFilterChecked ? true : !salesOrder.test;
      })
      .filter((salesOrder) => {
        return state.isDatePickerPicked
          ? !(salesOrder.SOIssueDate.indexOf(state.isDatePickerPicked) == -1)
          : true;
      })
      .filter((salesOrder) => {
        return state.isShipmentDatePicked
          ? !(
              salesOrder.DateofShipment.indexOf(state.isShipmentDatePicked) ==
              -1
            )
          : true;
      }),
    customerList: state.customerList,
    company: state.company,
  }),
  (dispatch) => ({
    getCustomerList: bindActionCreators(getCustomerList, dispatch),
    getSalesOrderList: bindActionCreators(getSalesOrderList, dispatch),
    setTestFilterEnable: bindActionCreators(setTestFilterEnable, dispatch),
    setDateFilterEnable: bindActionCreators(setDateFilterEnable, dispatch),
    setShipmentDateFilterEnable: bindActionCreators(
      setShipmentDateFilterEnable,
      dispatch
    ),
  })
)(SalesOrderListContainer);

SalesOrderListContainer.PropTypes = propTypes;
SalesOrderListContainer.defaultProps = defaultProps;
