import React, { PureComponent, Fragment } from 'react';
import NewSalesOrder from '../NewSalesOrder';
import { FingurLoader } from '../../General/FingurLoader';
import axios from '../../../util/CustomAxios';
import { Form, List, Dimmer, Loader, Image, Message } from 'semantic-ui-react';

class ShippingForm extends PureComponent {
  state = {
    isLoading: false,
  };

  async componentDidMount() {
    const id = this.props.soid;

    this.setState({
      isLoading: true,
    });

    const res = await axios.get(`/ship/${id}`);

    this.setState({
      isLoading: false,
      data: res.data,
    });
  }

  onClick = async () => {
    const id = this.props.soid;

    this.setState({
      isLoading: true,
    });

    const res = this.state.data;
    //const res = await axios.post(`/ship/${id}`);
    console.log(res);

    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { data } = this.state;

    let component = null;

    if (data) {
      const { id, number, address, items } = data;

      const addressConfirmationComponents = [
        <h1>Please check out the shipping address.</h1>,
        <Form warning>
          <Form.Group widths="equal">
            <Form.Input label="SO Id" readOnly value={id} />
            <Form.Input label="SO Number" readOnly value={number} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Country" value={address.country} />
            <Form.Input label="State" value={address.state} />
            <Form.Input label="City" value={address.city} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Street 1" value={address.address} />
            <Form.Input label="Street 2" />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Company" value={address.name} />
            <Form.Input label="Name" />
          </Form.Group>
          <Message warning header="회사명과 수신인을 확인해주세요!" />
          <Form.Group widths="equal">
            <Form.Input label="Phone" value={address.phone} />
            <Form.Input label="Mobile Phone" value={address.cellphone} />
          </Form.Group>
          {/* <List divided relaxed>
                                    {listItems}
                                </List> */}
          {/* <Form.TextArea label='JSON' autoheight value={JSON.stringify(data)} /> */}
          <Form.Button content="Submit" onClick={this.onClick}>
            Ship
          </Form.Button>
        </Form>,
      ];
      // const listItems = items.map(x => {
      //     const { description, quantity, currency, amount, hs_code, parcel } = x;
      //     return (
      //         <List.Item>
      //             <Form.Group widths='equal'>
      //                 <Form.Input label='Description' value={description} />
      //                 <Form.Input label='HS Code' value={hs_code} />
      //             </Form.Group>
      //             <Form.Group widths='equal'>
      //                 <Form.Input label='Quantity' value={quantity} />
      //                 <Form.Input label='Currency' value={currency} />
      //                 <Form.Input label='Amount' value={amount} />
      //             </Form.Group>
      //             <Form.Group inline widths='equal'>
      //                 <Form.Input label='Weight' value={parcel.weight} />
      //                 <Form.Input label='Width' value={parcel.width} />
      //                 <Form.Input label='Length' value={parcel.length} />
      //                 <Form.Input label='Height' value={parcel.height} />
      //             </Form.Group>
      //         </List.Item>
      //     );
      // });

      component = addressConfirmationComponents;
    } else {
      component = (
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      );
    }

    return (
      <Fragment>
        <Dimmer active={this.state.isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        {component}
      </Fragment>
    );
  }
}

export default ShippingForm;
