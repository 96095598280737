import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCustomerList,
  getCustomerAddress,
  setSelectedCustomer,
} from '../../../action/customer';
import CustomerSelector from '../presenters/CustomerSelector';
import CustomerDetailContainer from './CustomerDetailContainer';
import { LoadingWrapper } from '../../General/LoadingWrapper';
import NewSalesOrder from '../NewSalesOrder';

const propTypes = {};

const defaultProps = {};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class NewSalesOrderContainer extends PureComponent {
  render() {
    return (
      <NewSalesOrder
        {...this.props}
        isEdit={this.props.isEdit}
        handleSubmit={(e) => {
          e.preventDefault();
        }}
      />
    );
  }
}

export default connect((state) => ({
  isSalesOrderFormDataLoading: state.isSalesOrderFormDataLoading,
}))(NewSalesOrderContainer);

NewSalesOrderContainer.PropTypes = propTypes;
NewSalesOrderContainer.defaultProps = defaultProps;
